import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import NKAPI from "../NKAPI";
import { useContext } from "react";
import Context from "../../store/context";
import Button from "../Elements/Button";
import { faComments } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";

export default function LogoutModal(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { globalState } = useContext(Context);

    let [steamappid, setSteamAppID] = useState("");

    useEffect(() => {
        if (NKAPI.platform === "steam") {
            switch (NKAPI.appID) {
                case 1:
                    setSteamAppID("1252780");
                    break;
                case 2:
                    setSteamAppID("444640");
                    break;
                case 3:
                    setSteamAppID("678800");
                    break;
                case 8:
                    setSteamAppID("881490");
                    break;
                case 11:
                    setSteamAppID("960090");
                    break;
                case 16:
                    setSteamAppID("979060");
                    break;
                case 17:
                    setSteamAppID("1276390");
                    break;
                default:
                    break;
            }
        }
    }, []);

    function closeModal() {
        props.setIsModalOpen(false);
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Logout"
            className="2xl:w-4/12 md:w-7/12 sm:w-8/12 w-11/12 min-w-96 h-full mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-14 lg:h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("logout.title")}
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto flex flex-col px-1">
                    <p
                        className="text-base lg:text-lg font-medium mb-4"
                        dangerouslySetInnerHTML={{
                            __html: t("logout.logged_in", {
                                displayName: globalState?.user?.displayName
                                    ? `<em>${globalState?.user?.displayName}</em>`
                                    : "a player"
                            })
                        }}
                    ></p>

                    {/* Platform specific body */}
                    {NKAPI.platform === "steam" && NKAPI.appID !== 11 && (
                        <div className="flex flex-col space-y-3">
                            <div className="w-full border-yellow-500 bg-yellow-50 border-2 rounded-md p-4">
                                <h1 className="text-base font-semibold">
                                    {t("common.important")}
                                </h1>
                                <div className="text-base">
                                    <p>{t("logout.steam_warning")}</p>
                                </div>
                            </div>
                            <p className="text-base lg:text-lg font-medium">
                                {t("logout.steam_uninstall")}
                            </p>
                            <p className="text-base lg:text-lg font-medium">
                                {t("logout.steam_files")}
                            </p>
                            <p
                                className="text-sm text-gray-700 bg-gray-200 rounded-md p-1 px-3"
                                dangerouslySetInnerHTML={{
                                    __html: t("logout.steam_windir", {
                                        steamppid: steamappid
                                    })
                                }}
                            ></p>
                            <p
                                className="text-sm text-gray-700 bg-gray-200 rounded-md p-1 px-3"
                                dangerouslySetInnerHTML={{
                                    __html: t("logout.steam_macdir", {
                                        steamppid: steamappid
                                    })
                                }}
                            ></p>
                        </div>
                    )}
                    {NKAPI.platform === "steam" &&
                        NKAPI.appID === 11 &&
                        navigator.userAgent
                            .toString()
                            .toLowerCase()
                            .indexOf("macos") === -1 && (
                            <div className="flex flex-col space-y-3">
                                <p className="text-base lg:text-lg font-medium">
                                    {t("logout.steam_btd6")}
                                </p>
                            </div>
                        )}
                    {NKAPI.platform === "ios" && (
                        <div className="flex flex-col space-y-3">
                            <p className="text-base lg:text-lg font-medium">
                                {t("logout.ios")}
                            </p>
                        </div>
                    )}
                    {NKAPI.platform === "android" && (
                        <div className="flex flex-col space-y-3">
                            <p className="text-base lg:text-lg font-medium">
                                {t("logout.android_intro")}
                            </p>

                            <ol className="list-decimal list-inside ml-5 text-base lg:text-lg font-medium space-y-1">
                                <li>{t("logout.android_step_1")}</li>
                                <li>{t("logout.android_step_2")}</li>
                                <li>{t("logout.android_step_3")}</li>
                                <li>{t("logout.android_step_4")}</li>
                                <li>{t("logout.android_step_5")}</li>
                            </ol>
                        </div>
                    )}
                    {NKAPI.platform === "amazon" && (
                        <div className="flex flex-col space-y-3">
                            <p
                                className="text-base lg:text-lg font-medium"
                                dangerouslySetInnerHTML={{
                                    __html: t("logout.amazon")
                                }}
                            ></p>
                        </div>
                    )}
                    {/* Default Instruction */}
                    {NKAPI.platform !== "steam" &&
                        NKAPI.platform !== "ios" &&
                        NKAPI.platform !== "android" &&
                        NKAPI.platform !== "amazon" && (
                            <div className="flex flex-col space-y-3">
                                <p className="text-base lg:text-lg font-medium">
                                    {t("logout.default")}
                                </p>
                            </div>
                        )}

                    {/* Warnings */}
                    {globalState.user.providersAvailable &&
                    globalState.user.providersAvailable.length === 1 &&
                    globalState.user.providersAvailable[0] === "anon" &&
                    globalState.user.age === 1 ? (
                        //  Child Account Warning
                        <div className="mt-4 w-full border-yellow-500 bg-yellow-50 border-2 rounded-md p-4">
                            <div className="text-base">
                                <p>{t("logout.warning_child")}</p>
                            </div>
                        </div>
                    ) : (
                        //  Full Account Warning
                        <div className="mt-4 w-full border-yellow-500 bg-yellow-50 border-2 rounded-md p-4">
                            <div className="text-base">
                                <p>{t("logout.warning_full")}</p>
                            </div>
                        </div>
                    )}

                    {/* Buttons */}
                    <Button
                        div={
                            <div className="flex text-white items-center">
                                <p className="font-semibold tracking-wide text-base lg:text-lg">
                                    {t("contact.title")}
                                </p>{" "}
                                <FontAwesomeIcon
                                    className="ml-3 text-xl"
                                    icon={faComments}
                                />
                            </div>
                        }
                        color="nk"
                        className="w-full md:w-80 mt-4"
                        onClick={() => {
                            navigate("/contact");
                            closeModal();
                        }}
                    />
                    <Button
                        text={t("common.back")}
                        color="gray-400"
                        className="w-44 mt-4"
                        onClick={() => {
                            closeModal();
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
}
