import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import Context from "../../store/context";

export default function ChildUpgradeModal(props) {
    const { t } = useTranslation();
    const { globalDispatch } = useContext(Context);

    let [isUpgradeComplete, setIsUpgradeComplete] = useState(false);

    function closeModal() {
        setIsUpgradeComplete(false);
        props.setIsModalOpen(false);
    }

    function upgradeChildAccount() {
        NKAPI.request("/user/upgrade", {})
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                // Upgrade done, display completion text
                setIsUpgradeComplete(true);
            });
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Child Account Upgrade Modal"
            className="2xl:w-5/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
            shouldCloseOnOverlayClick={false}
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("profile.child_upgrade")}
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-400 text-3xl lg:text-5xl cursor-pointer hover:text-white"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                {isUpgradeComplete ? (
                    // Account Upgrade Complete
                    <div>
                        <p>{t("profile.child_upgrade_complete")}</p>
                        <div className="mt-4 w-full border-blue-500 bg-blue-600 text-gray-100 border-2 rounded-md p-4">
                            <div className="text-base">
                                <p className="text-center">
                                    {t("profile.child_upgrade_restart")}
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    // Start Account Upgrade
                    <div className="flex-1 overflow-y-auto p-1 text-base lg:text-lg">
                        <p>{t("profile.child_upgrade_intro")}</p>
                        <p className="mt-2">
                            {t("profile.child_upgrade_legal")}
                        </p>

                        {/* Legal Links */}
                        <div className="text-nk font-medium flex items-center space-x-2 mt-2">
                            <button
                                className="bg-gray-800 focus:outline-none text-left text-base md:text-lg font-medium"
                                onClick={() => {
                                    globalDispatch({
                                        type: "modal/terms",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                    closeModal();
                                }}
                            >
                                {t("common.terms")}
                            </button>
                            <div className="bg-white mx-4 rounded-full w-1 h-1"></div>
                            <button
                                className="bg-gray-800 focus:outline-none text-left text-base md:text-lg font-medium"
                                onClick={() => {
                                    globalDispatch({
                                        type: "modal/priv",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                    closeModal();
                                }}
                            >
                                {t("common.privacy")}
                            </button>
                        </div>

                        <p className="mt-2 text-sm lg:text-base text-gray-200">
                            {t("profile.child_upgrade_support")}
                        </p>

                        <Button
                            className="mt-4"
                            text={t("profile.child_upgrade")}
                            onClick={upgradeChildAccount}
                        />
                    </div>
                )}

                <Button
                    className="mt-2 w-48"
                    color="gray-400"
                    text={t("common.back")}
                    onClick={closeModal}
                />
            </div>
        </Modal>
    );
}
