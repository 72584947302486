import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faKey } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import { useContext, useEffect, useState } from "react";
import Context from "../../store/context";
import { useTranslation } from "react-i18next";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";

export default function OpenData() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    let [apiKey, setApiKey] = useState("");
    let [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    function closeModal() {
        globalDispatch({
            type: "modal/opendata",
            payload: {
                isShown: false
            }
        });
    }

    function GenerateAPIKey(action = "GET") {
        if (isLoading) return;

        setIsLoading(true);
        NKAPI.request("/user/opendata/key", { action: action })
            .then((data) => {
                let key = JSON.parse(data.data.data).key;
                setApiKey(key ? key : "");
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // On Mount
    useEffect(() => {
        GenerateAPIKey();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            isOpen={globalState?.isOpenDataModalShown}
            onRequestClose={closeModal}
            contentLabel="Open Data API"
            className="2xl:w-6/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full flex flex-col px-5 md:px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-14 lg:h-20 flex justify-between items-center">
                    {/* TODO: Translate */}
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        Open Data API
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-400 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1 space-y-4">
                    {/* TODO: Translate */}
                    <p className="text-lg">
                        You can generate, update or delete your Open Access Key
                        (OAK) for the Ninja Kiwi Open Data API here. You can
                        read more about the Open Data API at{" "}
                        <a
                            href="https://ninja.kiwi/opendatafaq"
                            target="_blank"
                            rel="noreferrer"
                            className="text-nk"
                        >
                            https://ninja.kiwi/opendatafaq
                        </a>
                    </p>
                    <Input
                        id="opendata_key"
                        icon={faKey}
                        placeholder="No API key generated"
                        type="text"
                        value={apiKey}
                        readOnly={true}
                    />
                    <Button
                        text="Generate New API Key"
                        color="green-500"
                        className="w-96 mt-4"
                        onClick={() => {
                            GenerateAPIKey("REFRESH");
                        }}
                        spinner={isLoading}
                    />
                    {apiKey ? (
                        <Button
                            text="Revoke API Key"
                            className="w-96 mt-2"
                            onClick={() => {
                                GenerateAPIKey("DELETE");
                            }}
                            spinner={isLoading}
                        />
                    ) : null}

                    <Button
                        text={t("common.back")}
                        color="gray-400"
                        className="w-44 mt-6"
                        onClick={() => {
                            closeModal();
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
}
