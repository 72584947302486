import LangSelector from "../Elements/LangSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import TermsModal from "../Modals/TermsModal";
import { useContext } from "react";
import Context from "../../store/context";
import PrivModal from "../Modals/PrivModal";
import Modal from "react-modal";
import OpenData from "../Modals/OpenDataModal";

Modal.setAppElement("#root");

export default function Footer() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);
    const { t } = useTranslation();

    function openTermModal() {
        globalDispatch({
            type: "modal/terms",
            payload: {
                isShown: true
            }
        });
    }
    function openPrivModal() {
        globalDispatch({
            type: "modal/priv",
            payload: {
                isShown: true
            }
        });
    }
    function openOpenDataAPIModal() {
        globalDispatch({
            type: "modal/opendata",
            payload: {
                isShown: true
            }
        });
    }

    return (
        <footer className="py-2 bg-gray-800 flex flex-col">
            <div className="flex-center flex-wrap">
                <h5
                    onClick={openTermModal}
                    className="text-white hover:text-gray-200 font-semibold text-sm lg:text-lg uppercase tracking-wide cursor-pointer"
                >
                    {t("common.terms_short")}
                </h5>
                <FontAwesomeIcon
                    className="p-1 w-2 h-2 text-gray-500 mx-4"
                    icon={faCircle}
                />
                <h5
                    onClick={openPrivModal}
                    className="text-white hover:text-gray-200 font-semibold text-sm lg:text-lg uppercase tracking-wide cursor-pointer"
                >
                    {t("common.privacy_short")}
                </h5>

                {globalState?.user?.nkapiID ? (
                    <>
                        <FontAwesomeIcon
                            className="p-1 w-2 h-2 text-gray-500 mx-4"
                            icon={faCircle}
                        />
                        {/* TODO: Translate */}
                        <h5
                            onClick={openOpenDataAPIModal}
                            className="text-white hover:text-gray-200 font-semibold text-sm lg:text-lg uppercase tracking-wide cursor-pointer"
                        >
                            Open Data API
                        </h5>
                    </>
                ) : null}

                <FontAwesomeIcon
                    className="p-1 w-2 h-2 text-gray-500 mx-4"
                    icon={faCircle}
                />
                <LangSelector />
            </div>

            {/* Legal Modals */}
            {globalState?.isTermsModalShown ? <TermsModal /> : null}
            {globalState?.isPrivModalShown ? <PrivModal /> : null}
            {globalState?.isOpenDataModalShown ? <OpenData /> : null}
        </footer>
    );
}
