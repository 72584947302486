import Axios from "axios";
import MD5 from "md5-hex";

const NKAPI = {
    appID: 0,
    skuID: 0,
    skuSignature: "",
    availableProviders: [],
    loggedinProviderIDs: [],
    hasProviderErrored: false,
    errorFromClient: "",
    sessionID: "",
    loggingInWithSession: false,
    isAppLoaded: false,
    version: "",
    device: "",
    platform: "",
    debug: "",
    locale: "",
    theme: "",
    user: {},
    unityMode: false,
    // Old API injection functions
    setCurrentProviders: function (providerIDs) {
        NKAPI.loggedinProviderIDs = providerIDs;
    },
    translateProviders: function (providerArray) {
        // Translate providers
        if (providerArray.indexOf("tw") > -1) {
            providerArray[providerArray.indexOf("tw")] = "twitch";
        }
        if (providerArray.indexOf("apl") > -1) {
            providerArray[providerArray.indexOf("apl")] = "apple";
        }
        if (providerArray.indexOf("fb") > -1) {
            providerArray[providerArray.indexOf("fb")] = "facebook";
        }
        if (providerArray.indexOf("gcir") > -1) {
            providerArray[providerArray.indexOf("gcir")] = "gamecircle";
        }
        if (providerArray.indexOf("gc") > -1) {
            providerArray[providerArray.indexOf("gc")] = "gamecenter";
        }
        if (providerArray.indexOf("android_with_gp") > -1) {
            providerArray[providerArray.indexOf("android_with_gp")] =
                "googleplay";
        }
        if (providerArray.indexOf("gp") > -1) {
            providerArray[providerArray.indexOf("gp")] = "googleplay";
        }

        return providerArray;
    },
    translateLocales: function (locale) {
        switch (locale) {
            case "chs": // Chinese Simplified
            case "zh":
                return "zh_cn";
            case "cht": // Chinese Traditional
            case "zh-hant":
                return "zh_tw";
            case "tur": // Turkish
                return "tr";
            case "nb": // Norwegian
                return "no";
            case "pt-br": // Portugese
                return "pt";

            default:
                return locale;
        }
    },
    setSession: function (sessionID) {
        NKAPI.sessionID = sessionID;
    },
    setAppInfo: function (appInfo) {
        NKAPI.appID = parseInt(appInfo[0]);
        NKAPI.skuID = parseInt(appInfo[1]);
        NKAPI.skuSignature = appInfo[2];
    },
    request: (endpoint, body, method) => {
        if (!endpoint) return;

        let bodyString = body ? JSON.stringify(body) : "";

        // Get SessionID (only when logged in)
        // let sessionID = session?.sessionID ? session?.sessionID : "";

        // let skuSig = NKAPI.skuSignature;

        // Setup Signatures
        let md5 = "";
        var nonce =
            new Date().getTime().toString(36) +
            "+" +
            Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

        if (bodyString.length > 1024 * 1024) {
            let length = 1024 * 1024;
            md5 =
                "W/" +
                length +
                "/" +
                MD5(
                    NKAPI.sessionID +
                        NKAPI.skuSignature +
                        bodyString.substr(0, length) +
                        nonce
                );
        } else if (NKAPI.sessionID != null) {
            md5 = MD5(
                NKAPI.sessionID + NKAPI.skuSignature + bodyString + nonce
            );
        } else {
            md5 = MD5(NKAPI.skuSignature + bodyString + nonce);
        }

        let postBody = {
            data: bodyString,
            auth: {
                appID: NKAPI.debug && !NKAPI.appID ? 17 : NKAPI.appID,
                skuID: NKAPI.debug && !NKAPI.skuID ? 1704 : NKAPI.skuID,
                session: NKAPI.sessionID ? NKAPI.sessionID : undefined
            },
            sig: md5,
            nonce: nonce
        };

        let apiHost = "";
        if (window.location.hostname.includes("staging"))
            apiHost = "https://api-staging.ninjakiwi.com";
        else if (
            window.location.hostname.includes("localhost") ||
            window.location.hostname.includes("127.0.0.1") ||
            window.location.hostname.startsWith("192.168")
        )
            apiHost = "http://127.0.0.1";
        else apiHost = "https://api.ninjakiwi.com";

        // Return promise of request
        return Axios({
            method: method ? method : "post",
            url: apiHost + endpoint,
            data: postBody,
            responseType: "json",
            timeout: 10000,
            headers: {
                nk_locale: NKAPI.locale ? NKAPI.locale : "en",
                "content-type": "application/json"
            }
        });
    },
    sendToSDK: function (url, data) {
        if (url === "//close") {
            //lets clean up and timers and intervals
            var tID = setTimeout(function () {}, 1000);
            while (tID !== -1) {
                clearTimeout(tID);
                tID--;
            }

            var iID = setInterval(function () {}, 1000);
            while (iID !== -1) {
                clearInterval(iID);
                iID--;
            }
        }

        // add the data to the url string, if any was passed
        if (data && !url.startsWith("//copytext")) {
            // base64 encode the data
            var dataAsBase64 = btoa(JSON.stringify(data));
            // add it as a query parameter
            if (url.indexOf("?") === -1) {
                url += "?data=" + dataAsBase64;
            } else {
                url += "&data=" + dataAsBase64;
            }
        }

        if (url.startsWith("//copytext")) {
            if (url.indexOf("?") === -1) {
                url += "?text=" + encodeURIComponent(data);
            } else {
                url += "&text=" + encodeURIComponent(data);
            }
        }

        // add the protocol to the start of the url
        if (NKAPI.unityMode) {
            url = "uniwebview:" + url;
        } else {
            url = "http:" + url;
        }

        if (NKAPI.getQueryVariable("platform") === "winrt") {
            var full =
                window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port ? ":" + window.location.port : "") +
                "/";
            url = url.replace("http://", full);
        }

        // finally navigate to the url
        if (NKAPI.debug) {
            console.log(url);
        } else {
            NKAPI.navigateWindow(url);
        }
    },
    navigateWindow: function (url) {
        if (NKAPI.getQueryVariable("platform") === "winrt") {
            window.location.href = url;
        } else {
            window.location = url;
        }
    },
    toggleTheme: function () {
        if (NKAPI.theme === "dark") {
            document.documentElement.classList.remove("dark");
            NKAPI.theme = "light";
        } else {
            document.documentElement.classList.add("dark");
            NKAPI.theme = "dark";
        }
    },
    getCountry: function () {
        let promises = [];

        // Get cloudflare worker
        promises.push(
            Axios({
                method: "get",
                url: "https://api.ninjakiwi.com/eu",
                responseType: "text/html; charset=utf-8",
                timeout: 180 * 1000
            })
        );

        // Get link endpoint
        promises.push(NKAPI.request("/utility/country", {}));

        // Return race of promises, we only need the first one to resolve
        return Promise.race(promises);
    },
    getNamesList: function () {
        return Axios({
            method: "get",
            url: "https://static-api.nkstatic.com/appdocs/4/appdocs/prettynames.json",
            responseType: "json",
            timeout: 10 * 1000
        });
    },
    getQueryVariable: function (variable) {
        var query = window.location.hash.substring(1);
        if (!query) query = window.location.search.substring(1); // If no hash, check query string

        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    },
    validateEmail: function (email) {
        var tester =
            /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

        if (!email) return false;

        if (email.length > 254) return false;

        var valid = tester.test(email);
        if (!valid) return false;

        // Further checking of some things regex can't handle
        var parts = email.split("@");
        if (parts[0].length > 64) return false;

        var domainParts = parts[1].split(".");
        if (
            domainParts.some(function (part) {
                return part.length > 63;
            })
        ) {
            return false;
        }

        return true;
    },
    hiddenSupportInformation: "",
    isStaging: function () {
        return window.location.hostname.includes("webview-staging")
            ? true
            : false;
    },
    isDev: function () {
        return window.location.hostname.includes("localhost") ||
            window.location.hostname.includes("127.0.0.1")
            ? true
            : false;
    },

    gotoProfile: function () {
        switch (NKAPI.appID) {
            case 11:
            case 21:
                return true;
            default:
                return false;
        }
    }
};

export default NKAPI;
