import { faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef, useContext } from "react";
import NKAPI from "../NKAPI";
import Context from "../../store/context";

function Input(props) {
    const { globalDispatch } = useContext(Context);

    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);

    function onFocus() {
        if (NKAPI.platform === "android" && window.innerHeight < 500) {
            // Hide footer & header
            globalDispatch({
                type: "ui/hide"
            });
        }
    }
    function onBlur() {
        if (NKAPI.platform === "android" && window.innerHeight < 500) {
            // Show footer & header
            globalDispatch({
                type: "ui/show"
            });
        }
    }

    return (
        <div
            className={`${
                props.className ? props.className : ""
            } flex items-center relative bg-gray-600 text-white rounded-full ${
                isFocused
                    ? "ring-2 ring-nk ring-offset-2 ring-offset-gray-800"
                    : ""
            }`}
            onClick={() => {
                setIsFocused(true);
                inputRef.current.focus();
            }}
        >
            {/* Left Icon */}
            {props.icon && (
                <FontAwesomeIcon
                    className={`text-2xl lg:text-3xl ml-4 ${
                        isFocused ? "text-nk" : "text-gray-300"
                    }`}
                    icon={props.icon}
                />
            )}

            {/* Label */}
            {props.label && (
                <p className="pl-3 text-base lg:text-xl text-gray-400 select-none flex-shrink-0">
                    {props.label}
                </p>
            )}

            {/* Input */}
            <input
                className="w-full h-12 lg:h-14 text-base lg:text-xl px-3 outline-none rounded-full bg-gray-600 text-white placeholder-gray-300"
                type={props.type}
                placeholder={props.placeholder}
                name={props.name}
                id={props.id}
                onChange={props.onChange}
                readOnly={props.readOnly}
                ref={inputRef}
                value={props.value}
                onFocus={() => {
                    setIsFocused(true);
                    if (!props.readOnly) onFocus();
                }}
                onBlur={() => {
                    setIsFocused(false);
                    if (!props.readOnly) onBlur();
                }}
                autoComplete={props.autocomplete}
            />

            {props.editModal && (
                // Edit Button
                <div
                    className="group flex-center mr-2 cursor-pointer"
                    onClick={() => {
                        // Start editing
                        props.isEditModalOpen(true);
                    }}
                >
                    <div className="h-10 w-10 bg-gray-400 rounded-full opacity-0 group-hover:opacity-100"></div>
                    <FontAwesomeIcon
                        className="absolute text-xl text-gray-300 group-hover:text-white"
                        icon={faPen}
                    />
                </div>
            )}

            {/* Error Text */}
            {props.state && (
                <div className="bg-nk rounded-full text-white font-semibold text-xs lg:text-sm px-2 lg:px-4 py-1 mr-4 bg-opacity-80 absolute right-0 lg:bg-opacity-100 lg:relative lg:flex-shrink-0">
                    {props.errorText}
                </div>
            )}
        </div>
    );
}

export default Input;
