import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCopyright } from "@fortawesome/pro-regular-svg-icons";
import Modal from "react-modal";
import {
    faDiscord,
    faFacebook,
    faInstagram,
    faReddit,
    faTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { faComments } from "@fortawesome/pro-solid-svg-icons";
import PackageData from "../../../package.json";
import Button from "../Elements/Button";
import { useContext } from "react";
import Context from "../../store/context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AboutModal(props) {
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation();

    const navigate = useNavigate();

    function closeModal() {
        props.setIsModalOpen(false);
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="About"
            className="2xl:w-4/12 md:w-7/12 sm:w-8/12 w-11/12 min-w-96 h-full mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-14 lg:h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("misc.about")}
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-400 text-3xl lg:text-5xl cursor-pointer hover:text-white"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto px-1">
                    {/* Support */}
                    <div className="mb-4 lg:mb-10">
                        <h3 className="text-lg lg:text-2xl font-semibold mb-2">
                            {t("common.support")}
                        </h3>
                        <a
                            className="text-nk text-lg font-medium"
                            href="https://ninja.kiwi/support"
                        >
                            Ninja Kiwi Support FAQ
                        </a>
                        {globalState?.user?.nkapiID && (
                            <Button
                                div={
                                    <div className="flex text-white items-center">
                                        <p className="font-semibold tracking-wide text-base lg:text-lg">
                                            {t("contact.title")}
                                        </p>{" "}
                                        <FontAwesomeIcon
                                            className="mx-3 text-xl"
                                            icon={faComments}
                                        />
                                    </div>
                                }
                                color="nk"
                                className="w-full md:w-80 mt-2"
                                onClick={() => {
                                    navigate("/contact");
                                    closeModal();
                                }}
                            />
                        )}
                    </div>

                    {/* Socials */}
                    <div className="mb-4 lg:mb-10">
                        <h3 className="text-lg lg:text-2xl font-semibold mb-2">
                            {t("misc.socials")}
                        </h3>
                        <div className="flex flex-wrap justify-start">
                            <a
                                className="text-3xl lg:text-4xl mb-2 lg:mb-0 mr-6 text-white hover:text-gray-200"
                                href="https://ninja.kiwi/discord"
                                title="Discord"
                            >
                                <FontAwesomeIcon icon={faDiscord} />
                            </a>
                            <a
                                className="text-3xl lg:text-4xl mb-2 lg:mb-0 mr-6 text-white hover:text-gray-200"
                                href="https://ninja.kiwi/facebook"
                                title="Facebook"
                            >
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a
                                className="text-3xl lg:text-4xl mb-2 lg:mb-0 mr-6 text-white hover:text-gray-200"
                                href="https://ninja.kiwi/twitter"
                                title="Twitter"
                            >
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a
                                className="text-3xl lg:text-4xl mb-2 lg:mb-0 mr-6 text-white hover:text-gray-200"
                                href="https://ninja.kiwi/youtube"
                                title="YouTube"
                            >
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                            <a
                                className="text-3xl lg:text-4xl mb-2 lg:mb-0 mr-6 text-white hover:text-gray-200"
                                href="https://ninja.kiwi/reddit"
                                title="Reddit"
                            >
                                <FontAwesomeIcon icon={faReddit} />
                            </a>
                            <a
                                className="text-3xl lg:text-4xl mb-2 lg:mb-0 mr-6 text-white hover:text-gray-200"
                                href="https://ninja.kiwi/insta"
                                title="Instagram"
                            >
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                    </div>

                    {/* About */}
                    <div className="mb-4 lg:mb-10">
                        <h3 className="text-lg lg:text-2xl font-semibold mb-2">
                            {t("misc.about")}
                        </h3>
                        <p className="mb-2 text-base md:text-lg font-medium text-gray-300">
                            Copyright {new Date().getFullYear()}{" "}
                            <FontAwesomeIcon icon={faCopyright} /> Ninja Kiwi
                            Ltd.
                        </p>
                        <div className="text-nk text-base md:text-lg font-medium mb-2">
                            <a href="https://ninjakiwi.com">
                                www.ninjakiwi.com
                            </a>
                            <br />
                            <button
                                className="bg-gray-800 focus:outline-none text-left text-nk text-base md:text-lg font-medium mt-2"
                                onClick={() => {
                                    globalDispatch({
                                        type: "modal/terms",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                    closeModal();
                                }}
                            >
                                {t("common.terms")}
                            </button>
                            <br />
                            <button
                                className="bg-gray-800 focus:outline-none text-left text-nk text-base md:text-lg font-medium mt-2"
                                onClick={() => {
                                    globalDispatch({
                                        type: "modal/priv",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                    closeModal();
                                }}
                            >
                                {t("common.privacy")}
                            </button>
                        </div>
                        <p className="text-base md:text-lg text-gray-300 font-medium">
                            LiNK WebView:{" "}
                            <span className="text-gray-100 tracking-wider">
                                v{PackageData.version}{" "}
                                {window.location.hostname.includes("staging") ||
                                window.location.hostname.includes("localhost")
                                    ? "(staging)"
                                    : ""}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
