import { useContext } from "react";
import Context from "../store/context";
import LiNKLogo from "../images/nk_splash_800_tiny.png";
import LiNKLogo1600 from "../images/nk_splash_1600_tiny.png";

export default function Hero() {
    const { globalState } = useContext(Context);

    return (
        <div
            className={`h-screen md:h-full w-full md:flex-1 bg-gradient-to-b from-nk-900 to-nk-800 flex-center ${
                globalState.layoutFull ? "hero_hide" : "hero_show"
            }`}
        >
            <div className="w-full relative" style={{ paddingBottom: "100%" }}>
                <img
                    src={window.screen.width > 1600 ? LiNKLogo1600 : LiNKLogo}
                    alt="Ninja Kiwi LiNK"
                    className="absolute top-0 bottom-0 left-0 right-0 m-auto max-h-screen"
                />
            </div>
        </div>
    );
}
