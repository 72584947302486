import Button from "../../../components/Elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/pro-duotone-svg-icons";
import { faArrowCircleDown } from "@fortawesome/pro-duotone-svg-icons";

import { useContext, useEffect, useState } from "react";
import Context from "../../../store/context";
import { useTranslation } from "react-i18next";

function SignUp_AgeGate() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation();

    const [ageError, setAgeError] = useState(false);

    useEffect(() => {
        // Set to small layout
        if (globalState.layoutFull) {
            globalDispatch({
                type: "layout/update",
                payload: {
                    layoutFull: false
                }
            });
        }
        // eslint-disable-next-line
    }, []);

    function submitAge(e) {
        e.preventDefault();

        let el = document.getElementById("signUp_age");
        let newAge = el.value ? parseInt(el.value) : 0;
        if (newAge > 0) {
            // Set age state
            globalDispatch({
                type: "onboarding/age",
                payload: {
                    age: newAge
                }
            });
        } else {
            setAgeError(true);
        }
    }

    return (
        <div>
            <p className="font-medium text-base lg:text-xl text-white">
                {t("onboarding.signup_intro")}
            </p>
            <form onSubmit={submitAge}>
                {/* Age Input */}
                <div className="flex flex-wrap items-center mt-4">
                    <input
                        name="age"
                        id="signUp_age"
                        className="rounded-full  bg-gray-600 text-white placeholder-gray-300 h-12 lg:h-14 w-16 lg:w-20 text-base lg:text-xl text-center focus:ring-2 focus:ring-nk focus:ring-offset-gray-800 focus:ring-offset-2 outline-none"
                        maxLength="3"
                        placeholder={t("onboarding.agegate_age")}
                        type="tel"
                    />
                    <div className="flex flex-col ml-2 mr-4 select-none">
                        <FontAwesomeIcon
                            className="h-6 w-6 lg:h-7 lg:w-7 text-3xl cursor-pointer text-gray-400 hover:text-white"
                            icon={faArrowCircleUp}
                            onClick={() => {
                                let el = document.getElementById("signUp_age");
                                let newVal =
                                    (el.value ? parseInt(el.value) : 0) + 1;
                                el.value = newVal >= 0 ? newVal : 0;
                                setAgeError(false);
                                // el.focus();
                            }}
                        />
                        <FontAwesomeIcon
                            className="h-6 w-6 lg:h-7 lg:w-7 text-3xl cursor-pointer text-gray-400 hover:text-white"
                            icon={faArrowCircleDown}
                            onClick={() => {
                                let el = document.getElementById("signUp_age");
                                let newVal =
                                    (el.value ? parseInt(el.value) : 0) - 1;
                                el.value = newVal >= 0 ? newVal : 0;
                                setAgeError(false);
                                // el.focus();
                            }}
                        />
                    </div>
                    <p className="text-gray-400 text-sm lg:text-lg leading-4 mr-4">
                        {t("onboarding.signup_instruction")}
                    </p>
                    {/* Error */}
                    {ageError && (
                        <div className="bg-nk bg-opacity-80 rounded-full text-white font-semibold text-sm px-4 py-1 my-2">
                            {t("error.signup_age")}
                        </div>
                    )}
                </div>
                <Button
                    width="w-36 lg:w-44 mt-4"
                    color="nk"
                    text={t("common.next")}
                    type="submit"
                />
            </form>
        </div>
    );
}

export default SignUp_AgeGate;
