import { useContext, useState } from "react";
import Context from "../../store/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUserCircle } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import { useTranslation } from "react-i18next";

export default function DisplayNameEditModal(props) {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [nameError, setNameError] = useState("");

    function closeModal() {
        props.setIsModalOpen(false);
        setNameError("");
    }

    function submitDisplayName(e) {
        e.preventDefault();

        let oldName = globalState?.user?.displayName;
        let newName = document
            .getElementById("profile_displayName")
            .value.trim();

        // Validate username
        if (newName === oldName) {
            setNameError(t("error.new_name"));
        } else if (newName.length > 14) {
            setNameError(t("error.name_toolong"));
        } else {
            setIsLoading(true);

            let newUser = { ...globalState.user }; // Shallow copy to stop referenceing of state obj
            newUser.displayName = newName;
            // Submit new user
            NKAPI.request("/user/update", newUser)
                .then((d) => {
                    // Update user state
                    globalDispatch({
                        type: "user/update",
                        payload: {
                            user: JSON.parse(d.data.data).user
                        }
                    });
                    // Send update request to game
                    NKAPI.sendToSDK("//user/update", JSON.parse(d.data.data));
                    setIsLoading(false);
                    closeModal();
                })
                .catch((e) => {
                    setIsLoading(false);

                    // Reset form
                    if (
                        e.response.data.error.type ===
                        "ERR_USER_CANNOT_EDIT_AT_THIS_TIME"
                    ) {
                        // Get time
                        let editIn = e.response.data.error.editIn;
                        // Get hours
                        let hoursUntilChange = Math.floor(
                            editIn / (60 * 60 * 1000)
                        );
                        hoursUntilChange = Math.max(hoursUntilChange, 1);
                        // Check if days
                        let daysUntilChange = 0;
                        if (hoursUntilChange > 48) {
                            daysUntilChange = Math.floor(hoursUntilChange / 24);
                        }
                        setNameError(
                            t("error.wait_time", {
                                time: daysUntilChange
                                    ? `${daysUntilChange} days`
                                    : `${hoursUntilChange} hours`
                            })
                        );
                    } else if (
                        e.response.data.error.type ===
                        "ERR_INPUT_CONTAINS_INVALID_WORD"
                    ) {
                        setNameError(t("error.invalid_word"));
                    } else {
                        setNameError(t("error.error_tryagain"));
                    }
                });
        }
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Change Display Name"
            className="2xl:w-6/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("misc.name_title")}
                    </h2>
                    {/* <span className="flag-icon flag-icon-gb"></span> */}
                    <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1">
                    <form onSubmit={submitDisplayName} className="space-y-4">
                        <Input
                            id="profile_displayName"
                            icon={faUserCircle}
                            placeholder={globalState?.user?.displayName}
                        />
                        <div className="flex items-center flex-wrap">
                            <Button
                                text={t("misc.name_update")}
                                spinner={isLoading}
                                type="submit"
                                className="w-64 mr-4"
                            />
                            {/* Error */}
                            {nameError && (
                                <div className="bg-nk rounded-full text-white font-semibold text-sm px-4 py-1 my-2">
                                    {nameError}
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}
