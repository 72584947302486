import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import { useTranslation } from "react-i18next";
import Context from "../../store/context";
import { faEnvelope, faShareAlt } from "@fortawesome/pro-solid-svg-icons";

export default function StatusRecoveryModal(props) {
    const { globalState } = useContext(Context);
    const { t } = useTranslation();

    function closeModal() {
        // Update recovery status
        NKAPI.request("/user/consent/update", {
            type: "recovery"
        }).finally(() => {
            props.setIsModalOpen(false);
        });
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Recovery Reminder"
            className="2xl:w-5/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("recoverypopup.title")}
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1">
                    {/* Intro */}
                    <div>
                        <p className="text-base lg:text-lg">
                            {t("recoverypopup.intro")}
                        </p>
                        <div className="bg-gray-600 flex-1 h-1 rounded my-4"></div>
                    </div>

                    <div className="flex flex-col space-y-6">
                        {/* Add Email */}
                        <div>
                            <Button
                                div={
                                    <div className="flex-center text-white font-semibold tracking-wide text-base lg:text-lg px-6 lg:px-10 leading-4 pointer-events-none">
                                        <FontAwesomeIcon
                                            className="text-white text-2xl lg:text-3xl mr-4"
                                            icon={faEnvelope}
                                        />
                                        <p>
                                            {globalState?.user?.providersAvailable?.indexOf(
                                                "kloud"
                                            ) > -1 &&
                                            globalState?.user?.email &&
                                            !globalState.user.email.includes(
                                                "@no.email"
                                            )
                                                ? t(
                                                      "recoverypopup.emailconnected"
                                                  )
                                                : t(
                                                      "recoverypopup.connectemail"
                                                  )}
                                        </p>
                                    </div>
                                }
                                disabled={
                                    globalState?.user?.providersAvailable?.indexOf(
                                        "kloud"
                                    ) > -1 &&
                                    globalState?.user?.email &&
                                    !globalState.user.email.includes(
                                        "@no.email"
                                    )
                                        ? true
                                        : false
                                }
                                onClick={() => {
                                    if (
                                        globalState?.user?.providersAvailable?.indexOf(
                                            "kloud"
                                        ) === -1
                                    ) {
                                        // Open Email Link Modal
                                        props.setLinkEmailModalOpen(true);

                                        // Close this modal
                                        closeModal();
                                    }
                                }}
                            />
                            <p className="text-gray-300 text-sm lg:text-base mt-2">
                                {t("recoverypopup.emaildesc")}
                            </p>
                        </div>

                        {/* View Recovery Email */}
                        <div>
                            <Button
                                div={
                                    <div className="flex-center text-white font-semibold tracking-wide text-base lg:text-lg px-6 lg:px-10 leading-4 pointer-events-none">
                                        <FontAwesomeIcon
                                            className="text-white text-2xl lg:text-3xl mr-4"
                                            icon={faShareAlt}
                                        />
                                        <p>{t("profile.recovery")}</p>
                                    </div>
                                }
                                onClick={() => {
                                    // Show recovery modal
                                    props.setRecoveryCodeModalOpen(true);

                                    // Close this modal
                                    closeModal();
                                }}
                            />
                            <p className="text-gray-300 text-sm lg:text-base mt-2">
                                {t("recoverypopup.recoverydesc")}
                            </p>
                        </div>
                    </div>

                    {/* Back */}
                    <Button
                        className="mt-8"
                        text={t("recoverypopup.notnow")}
                        color="gray-400"
                        onClick={closeModal}
                    />
                </div>
            </div>
        </Modal>
    );
}
