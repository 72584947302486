import Input from "../components/Elements/Input";
import Button from "../components/Elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useContext, useState } from "react";
import NKAPI from "../components/NKAPI";
import { useTranslation } from "react-i18next";
import Context from "../store/context";

export default function PasswordReset() {
    const { globalDispatch } = useContext(Context);

    const [isLoading, setIsLoading] = useState(false);
    const [passError, setPassError] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [passSuccess, setPassSuccess] = useState(false);
    const [passStrength, setPassStrength] = useState("bg-red-500 w-0");

    const { t } = useTranslation();

    function updatePassword(e) {
        e.preventDefault();

        let pass = document.getElementById("pass_Input").value;
        let passConf = document.getElementById("passConf_Input").value;

        if (pass && pass === passConf) {
            setIsLoading(true);
            // Update password
            NKAPI.request(
                "/support/confirmNewPassword",
                {
                    token: NKAPI.getQueryVariable("token"),
                    password: pass
                },
                "PUT"
            )
                .then(() => {
                    // Password reset, show message
                    setIsLoading(false);
                    setPassSuccess(true);
                })
                .catch((e) => {
                    setIsLoading(false);
                    // Error changing password, show message
                    setApiError(true);

                    if (e?.toJSON()?.message === "Network Error") {
                        // No internet connection, show lost connection screen
                        globalDispatch({
                            type: "modal/offline",
                            payload: {
                                isShown: true
                            }
                        });
                    }
                });
        } else {
            setPassError(true);
        }
    }

    function passwordStrength(e) {
        let pass = e.target.value;

        // Calculate strength
        let passScore = 0;

        // Length
        if (pass.length > 6) passScore += 1;

        // Symbol
        if (pass.match(/[|\\/~^:,;?!&%$@#*+]/)) passScore += 1;

        // Number
        if (pass.match(/[1234567890]/)) passScore += 1;

        // Uppercase
        for (let i = 0; i < pass.length; i++) {
            const char = pass[i];
            if (char === char.toUpperCase() && !parseInt(char)) {
                passScore += 1;
                break;
            }
        }

        // Set strength color
        if (passScore <= 1) setPassStrength("bg-red-500 w-1/3");
        // Low score
        else if (passScore > 1 && passScore <= 3)
            setPassStrength("bg-yellow-400 w-2/3");
        // Medium score
        else if (passScore >= 4) setPassStrength("bg-green-400 w-full"); // High score
    }

    return (
        <div className="h-full w-full px-1">
            <h1 className="text-lg lg:text-2xl font-semibold text-white">
                {t("resetpass.title")}
            </h1>

            {!apiError ? (
                !passSuccess ? (
                    <div>
                        <form
                            onSubmit={updatePassword}
                            className="my-4 space-y-4"
                        >
                            <Input
                                id="pass_Input"
                                placeholder={t("resetpass.newpass")}
                                icon={faLockAlt}
                                type="password"
                                onChange={passwordStrength}
                                autocomplete="new-password"
                            />
                            <Input
                                id="passConf_Input"
                                placeholder={t("common.pass_confirm")}
                                icon={faLockAlt}
                                errorText={t("error.pass_nomatch")}
                                state={passError}
                                type="password"
                                autocomplete="new-password"
                                onChange={() => {
                                    setPassError(false);
                                }}
                            />

                            <div className="flex flex-wrap">
                                <Button
                                    type="submit"
                                    text={t("resetpass.update")}
                                    color="nk"
                                    className="w-52"
                                    spinner={isLoading}
                                />
                                <div className="flex flex-col ml-4 justify-center">
                                    <p className="font-semibold">
                                        {t("resetpass.passstrength")}
                                    </p>
                                    <div
                                        id="passStrengthMeter"
                                        className="h-4 w-40 rounded-full bg-gray-200"
                                    >
                                        <div
                                            id="passStrengthMeter_Fill"
                                            className={`h-4 rounded-full transition-all duration-500 ${passStrength}`}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                ) : (
                    <div className="flex items-center mt-4">
                        <div className="text-green-400 text-3xl ml-2">
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <div
                            className="leading-5 text-lg font-medium ml-3"
                            dangerouslySetInnerHTML={{
                                __html: t("resetpass.success")
                            }}
                        ></div>
                    </div>
                )
            ) : (
                <div className="mt-4 w-full border-red-500 bg-red-50 border-2 rounded-md p-4">
                    <div className="text-base">
                        <p className="text-center">{t("resetpass.error")}</p>
                    </div>
                </div>
            )}
        </div>
    );
}
