import React from "react";
import useGlobalState from "./useGlobalState";
import Context from "./context";

// Setup template component to wrap the React app with. {children} is the page content
const GlobalStateProvider = ({ children }) => {
    return (
        <Context.Provider value={useGlobalState()}>{children}</Context.Provider>
    );
};

export default GlobalStateProvider;
