import Header from "./Layout/Header";
import Page from "./Layout/Page";
import Footer from "./Layout/Footer";
import { useContext } from "react";
import Context from "../store/context";
import ErrorPopup from "../components/ErrorPopup";

export default function Layout(props) {
    // Global State
    const { globalState } = useContext(Context);
    // Use global state to make layout full width, if user is not on login page

    return (
        <div
            className={`
            fixed h-screen top-0 md:relative md:transform-none md:h-full bg-gray-800 layout_shadow flex flex-col px-2 2xl:px-16 ${
                globalState.layoutFull ? "w-full" : "w-full md:w-6/12 xl:w-5/12"
            }`}
            style={{ transition: "width 0.5s, transform 0.2s" }}
        >
            <Header />

            <Page />

            {/* Hide on android when keyboard is shown */}
            {!globalState.softKeyboardShown && <Footer />}

            {/* Error Popup */}
            {globalState.isErrorShown && <ErrorPopup />}
        </div>
    );
}
