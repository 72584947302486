import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Context from "../store/context";

export default function ErrorPopup() {
    const { globalDispatch, globalState } = useContext(Context);
    const { t } = useTranslation();
    const navigate = useNavigate();

    function HidePopup() {
        globalDispatch({
            type: "modal/error",
            payload: {
                isShown: false
            }
        });
    }

    // useEffect(() => {
    //     // Auto hide popup
    //     if (globalState?.errorMsg !== "error.login_timedout") {
    //         setTimeout(() => {
    //             globalDispatch({
    //                 type: "modal/error",
    //                 payload: {
    //                     isShown: false
    //                 }
    //             });
    //         }, 30000);
    //         // eslint-disable-next-line
    //     }
    // }, []);

    return (
        <div className="flex-center absolute z-40 bottom-0 mb-10 w-auto left-0 right-0">
            <div className="bg-red-500 rounded-lg p-4 px-8 relative">
                <div
                    className="absolute top-0 right-0 px-3 py-1 mr-1 mt-2 cursor-pointer hover:text-nk text-white"
                    onClick={() => {
                        // Hide error popup
                        HidePopup();
                    }}
                >
                    <FontAwesomeIcon className="text-lg" icon={faTimes} />
                </div>

                <p className="text-left text-white lg:text-lg">
                    {globalState.errorMsg ? (
                        <>{t(globalState.errorMsg)}</>
                    ) : (
                        <>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: t("upgrade.error")
                                }}
                            ></span>{" "}
                            <button
                                className="text-white bg-gray-800 underline hover:no-underline"
                                onClick={() => {
                                    navigate("/contact");
                                    // Hide error popup
                                    HidePopup();
                                }}
                            >
                                {t("common.support")}
                            </button>
                            .
                        </>
                    )}
                </p>
            </div>
        </div>
    );
}
