import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../components/Elements/Button";
import NKAPI from "../components/NKAPI";
import Context from "../store/context";

export default function Upgrade() {
    const { globalDispatch } = useContext(Context);
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [upgradeError, setUpgradeError] = useState(false);
    const [upgradeSuccess, setUpgradeSuccess] = useState(false);

    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {}, []);

    function upgradeAccount() {
        setUpgradeError(false);
        if (!isChecked) return;

        let token = NKAPI.getQueryVariable("token");
        setIsLoading(true);

        NKAPI.request("/support/confirmUpgrade", { token: token })
            .then(() => {
                // Do success
                setUpgradeSuccess(true);
            })
            .catch(() => {
                // Do error
                setUpgradeError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className="px-1">
            <h1 className="text-lg lg:text-2xl font-semibold text-white">
                {t("upgrade.title")}
            </h1>

            {upgradeSuccess ? (
                <div className="flex items-center mt-4 mb-8">
                    <div className="text-green-400 text-3xl ml-2">
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div
                        className="leading-5 text-lg font-medium ml-3"
                        dangerouslySetInnerHTML={{
                            __html: t("upgrade.success")
                        }}
                    ></div>
                </div>
            ) : (
                <div className="space-y-4">
                    <p className="text-lg font-medium">{t("upgrade.intro")}</p>
                    <p className="text-lg font-medium">
                        {t("upgrade.consent")}
                    </p>

                    {/* Terms Agreement */}
                    <div className="flex items-center">
                        <div
                            className={`h-12 w-12 lg:h-14 lg:w-14 rounded-full flex-center cursor-pointer ${
                                isChecked
                                    ? "bg-nk hover:bg-nk-600 ring-2 ring-nk ring-offset-white ring-offset-2"
                                    : "bg-gray-200 hover:bg-gray-300"
                            }`}
                            id="signUp_legal"
                            onClick={() => {
                                setIsChecked(!isChecked);
                            }}
                        >
                            <FontAwesomeIcon
                                className={`pointer-events-none text-2xl text-white ${
                                    isChecked ? "visible" : "hidden"
                                }`}
                                icon={faCheck}
                            />
                        </div>
                        <p className="text-gray-500 flex-1 text-sm lg:text-lg pl-2 cursor-pointer leading-5 lg:leading-4">
                            <span
                                onClick={() => {
                                    setIsChecked(!isChecked);
                                }}
                            >
                                {t("common.agree")}{" "}
                            </span>
                            <button
                                className="text-nk text-base md:text-lg bg-gray-800 font-medium focus:outline-none"
                                onClick={() => {
                                    globalDispatch({
                                        type: "modal/terms",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                }}
                            >
                                {t("common.terms")}
                            </button>{" "}
                            &{" "}
                            <button
                                className="text-nk text-base md:text-lg bg-gray-800 font-medium focus:outline-none"
                                onClick={() => {
                                    globalDispatch({
                                        type: "modal/priv",
                                        payload: {
                                            isShown: true
                                        }
                                    });
                                }}
                            >
                                {t("common.privacy")}
                            </button>
                        </p>
                    </div>

                    <Button
                        text={t("upgrade.upgrade")}
                        className={`w-72 ${
                            isChecked ? "opacity-100" : "opacity-50"
                        }`}
                        color="nk"
                        onClick={upgradeAccount}
                        spinner={isLoading}
                    />

                    {/* Error */}
                    {upgradeError && (
                        <div className="flex-center border-red-500 bg-red-50 border-2 rounded-md p-4">
                            <p className="text-left text-gray-600">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t("upgrade.error")
                                    }}
                                ></span>{" "}
                                <button
                                    className="text-nk hover:text-nk-600 bg-red-50"
                                    onClick={() => {
                                        navigate("/contact");
                                    }}
                                >
                                    {t("common.support")}
                                </button>
                                .
                            </p>
                        </div>
                    )}
                </div>
            )}

            <Button
                text={t("common.back")}
                color="gray-400"
                className="w-48 mt-4"
                onClick={() => {
                    navigate("/");
                }}
            />
        </div>
    );
}
