// Icons
import { faAt } from "@fortawesome/pro-light-svg-icons";
import { faLockAlt } from "@fortawesome/pro-light-svg-icons";

import Button from "../../components/Elements/Button";
import Input from "../../components/Elements/Input";
import NKAPI from "../../components/NKAPI";
import { useContext, useEffect, useState } from "react";
import Context from "../../store/context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoginProviders from "../../components/LoginProviders";
import LiNKCodeModal from "../../components/Modals/LiNKCodeModal";

export default function Login() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation(); // locale

    const navigate = useNavigate();

    // Form States
    const [emailError, setEmailError] = useState(false);
    const [passError, setPassError] = useState(false);
    const [authError, setAuthError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function loginSubmit(e) {
        e.preventDefault();

        // Get form data
        let email = document.getElementById("login_email").value.trim();
        let pass = document.getElementById("login_password").value;

        // Check user has entere something
        if (!email || !pass) {
            if (!email) {
                setEmailError(true);
            }
            if (!pass) {
                setPassError(true);
            }

            return;
        }
        // Validate Email
        if (email && NKAPI.validateEmail(email)) {
            setEmailError(false);
        } else {
            setEmailError(true);
            return;
        }

        // Do auth request
        setIsLoading(true);
        NKAPI.request("/user/login/kloud", {
            email: email,
            password: pass
        })
            .then((data) => {
                let userData = JSON.parse(data.data.data);

                // Update user state
                globalDispatch({
                    type: "user/login",
                    payload: {
                        user: userData.user,
                        session: userData.session
                    }
                });

                setIsLoading(false);

                if (NKAPI.debug || NKAPI.gotoProfile()) {
                    navigate("/profile");
                    NKAPI.sendToSDK("//onlylogin", data.data);
                } else {
                    NKAPI.sendToSDK("//onlylogin", data.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);

                if (error?.toJSON()?.message === "Network Error") {
                    // No internet connection, show lost connection screen
                    globalDispatch({
                        type: "modal/offline",
                        payload: {
                            isShown: true
                        }
                    });
                } else {
                    setAuthError(true);
                }
            });
    }

    // On mount
    const [providerButtons, setProviderButtons] = useState([]);
    useEffect(() => {
        // Reset layout if coming back from full sign up
        if (globalState.layoutFull) {
            globalDispatch({
                type: "layout/update",
                payload: {
                    layoutFull: false
                }
            });
        }

        // Get enabled providers from url
        setProviderButtons(NKAPI.availableProviders);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {/* Login Form */}
            <form
                className="space-y-4"
                onSubmit={(e) => {
                    loginSubmit(e);
                }}
            >
                {/* Username / Email */}
                <Input
                    type="email"
                    placeholder={t("onboarding.email")}
                    name="email"
                    id="login_email"
                    onChange={() => {
                        setEmailError(false);
                        setAuthError(false);
                    }}
                    icon={faAt}
                    state={emailError}
                    errorText={t("error.valid_email")}
                />
                <Input
                    type="password"
                    placeholder={t("onboarding.password")}
                    name="password"
                    id="login_password"
                    onChange={() => {
                        setPassError(false);
                        setAuthError(false);
                    }}
                    icon={faLockAlt}
                    state={passError}
                    errorText={t("error.pass_req")}
                    autocomplete="current-password"
                />
                {/* Login Submit */}
                <div className="flex items-center">
                    <Button
                        text={t("onboarding.login")}
                        color="nk"
                        className="w-auto"
                        type="submit"
                        spinner={isLoading}
                    />
                    {/* Error */}
                    {authError && (
                        <div className="bg-nk rounded-full text-white font-semibold text-sm px-4 py-1 ml-4">
                            {t("error.invalid_creds")}
                        </div>
                    )}
                </div>
            </form>
            <button
                className="text-gray-200 hover:text-gray-300 font-medium lg:text-lg text-center mt-3 bg-gray-800"
                onClick={() => {
                    // Forgot password screen
                    navigate("/forgot");
                }}
            >
                {t("onboarding.forgot_pass")}
            </button>

            {/* Divider */}
            <div className="w-full flex items-center my-6 lg:my-12">
                <div className="bg-gray-600 flex-1 h-1 rounded"></div>
                <div className="text-gray-500 text-base lg:text-xl uppercase tracking-widest font-semibold px-6">
                    {t("common.or")}
                </div>
                <div className="bg-gray-600 flex-1 h-1 rounded"></div>
            </div>

            {/* Third-party Providers*/}
            <div className="flex flex-col space-y-4 pb-3">
                <LiNKCodeModal />

                {/* Dynamic Buttons */}
                <LoginProviders providersAvailable={providerButtons} />

                <button
                    className="text-gray-200 hover:text-gray-300 font-medium text-base lg:text-lg mx-auto text-center pt-1 bg-gray-800"
                    onClick={() => {
                        // Go to sign up page
                        globalDispatch({
                            type: "onboarding/signup"
                        });
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t("onboarding.no_account")
                    }}
                ></button>
            </div>
        </div>
    );
}
