import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../components/Elements/Button";
import NKAPI from "../components/NKAPI";

export default function Unsub() {
    const [isLoading, setIsLoading] = useState(true);
    const [wasSuccess, setWasSuccess] = useState(false);

    const navigate = useNavigate();

    const { t } = useTranslation();

    useEffect(() => {
        // Send unsub request
        var token = NKAPI.getQueryVariable("token");
        var shortcode = NKAPI.getQueryVariable("shortcode");

        axios
            .get(
                `https://api-staging.ninjakiwi.com/user/email/unsubscribe?shortcode=${shortcode}&token=${token}`
            )
            .then(() => {
                // console.log(d);

                setIsLoading(false);
                setWasSuccess(true);
            })
            .catch(() => {
                // console.log(e.response.data.error.type);

                setIsLoading(false);
                setWasSuccess(false);
            });
    }, []);

    return (
        <div className="px-1">
            <h1 className="text-lg lg:text-2xl font-semibold text-white">
                {t("unsub.title")}
            </h1>

            {isLoading ? (
                <div className="w-full flex-center my-8">
                    <FontAwesomeIcon
                        icon={faSpinnerThird}
                        className="fa-spin text-3xl text-nk"
                    />
                </div>
            ) : wasSuccess ? (
                <div className="flex items-center mt-4">
                    <div className="text-green-400 text-3xl ml-2">
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <div className="leading-5 text-lg font-medium ml-3">
                        {t("unsub.success")}
                    </div>
                </div>
            ) : (
                <div className="flex items-center mt-4">
                    <div className="text-red-400 text-3xl ml-2">
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <div className="leading-5 text-lg font-medium ml-3">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t("unsub.error")
                            }}
                        ></span>{" "}
                        <button
                            className="text-nk hover:text-nk-600 bg-gray-800"
                            onClick={() => {
                                navigate("/contact");
                            }}
                        >
                            {t("common.support")}
                        </button>
                    </div>
                </div>
            )}

            <Button
                text={t("common.back")}
                color="gray-400"
                className="mt-10"
                onClick={() => {
                    navigate("/");
                }}
            />
        </div>
    );
}
