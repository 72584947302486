import Input from "../components/Elements/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Button from "../components/Elements/Button";
import { faAt } from "@fortawesome/pro-light-svg-icons";
import NKAPI from "../components/NKAPI";

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Context from "../store/context";

function ForgotPassword() {
    const { globalDispatch } = useContext(Context);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const [emailError, setEmailError] = useState(false);
    const [recoverySent, setRecoverySent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function recoverPassword(e) {
        e.preventDefault();
        let email = document
            .getElementById("recoverPassword_Input")
            .value.trim();

        setRecoverySent(false);

        if (email && NKAPI.validateEmail(email)) {
            setEmailError(false);

            setIsLoading(true);
            // Submit recover pass request
            NKAPI.request("/support/generateNewPassword", { email: email })
                .then((data) => {
                    console.log(data);
                    setRecoverySent(true);
                })
                .catch((e) => {
                    if (e?.toJSON()?.message === "Network Error") {
                        // No internet connection, show lost connection screen
                        globalDispatch({
                            type: "modal/offline",
                            payload: {
                                isShown: true
                            }
                        });
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setEmailError(true);
        }
    }

    return (
        <div className="px-1">
            <h1 className="text-lg lg:text-2xl font-semibold text-white">
                {t("forgotpass.title")}
            </h1>

            <form
                onSubmit={recoverPassword}
                className="flex flex-col space-y-4"
            >
                <Input
                    className="mt-4"
                    placeholder={t("common.email")}
                    id="recoverPassword_Input"
                    icon={faAt}
                    state={emailError}
                    errorText={t("error.valid_email")}
                />

                <Button
                    className="bg-nk focus:ring-nk w-72"
                    text={
                        isLoading ? (
                            <FontAwesomeIcon
                                className="fa-spin text-2xl"
                                icon={faSpinnerThird}
                            />
                        ) : (
                            t("forgotpass.recover")
                        )
                    }
                    type="submit"
                    onClick={recoverPassword}
                />

                {recoverySent && (
                    <div className="flex items-center">
                        <div className="text-green-400 text-3xl ml-2">
                            <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <div
                            className="leading-5 text-lg font-medium ml-3"
                            dangerouslySetInnerHTML={{
                                __html: t("forgotpass.success")
                            }}
                        ></div>
                    </div>
                )}

                <Button
                    className="w-44"
                    type="button"
                    text={t("common.back")}
                    color="gray-400"
                    onClick={() => {
                        navigate("/");
                    }}
                />
            </form>
        </div>
    );
}

export default ForgotPassword;
