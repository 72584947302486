import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import Button from "../../components/Elements/Button";
import LiNKCodeModal from "../../components/Modals/LiNKCodeModal";
import LoginProviders from "../../components/LoginProviders";
import { useContext, useEffect } from "react";
import Context from "../../store/context";
import { useTranslation } from "react-i18next";

export default function SeenBefore(props) {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation();

    useEffect(() => {
        // Set to small layout
        if (globalState.layoutFull) {
            globalDispatch({
                type: "layout/update",
                payload: {
                    layoutFull: false
                }
            });
        }

        // console.log(props?.user?.providersAvailable);

        // eslint-disable-next-line
    }, []);

    return (
        <div className="h-full w-full px-1 text-white">
            <h1 className="text-xl lg:text-2xl font-semibold">
                {t("onboarding.seenbefore_weclome", {
                    displayName: props.user.displayName
                })}
            </h1>
            <p className="text-base lg:text-xl mb-8">
                {t("onboarding.seenbefore_intro")}
            </p>

            {!props?.user?.email && !props?.user?.providersAvailable ? (
                <div>
                    <LiNKCodeModal />
                </div>
            ) : (
                // Show email and/or providers
                <div className="flex flex-col space-y-4 my-4">
                    {/* Show email button, if kloud provider available TODO: Emails need a cutoff, can be too long */}
                    {props?.user?.email && (
                        <Button
                            text={
                                props?.user?.email
                                    ? props.user.email
                                    : t("onboarding.seenbefore_nkaccount")
                            }
                            className="bg-nk focus:ring-nk"
                            icon={faEnvelope}
                            onClick={() => {
                                // Switch back to main login page
                                props?.setIsUserSeen(false);
                            }}
                        />
                    )}
                    {/* Provider List */}
                    <LoginProviders
                        providersAvailable={props?.user?.providersAvailable}
                    />
                    <LiNKCodeModal />
                </div>
            )}

            {/* Divider */}
            <div className="w-full flex items-center my-6 lg:my-12">
                <div className="bg-gray-600 flex-1 h-1 rounded"></div>
                <div className="text-gray-500 text-base lg:text-xl uppercase tracking-widest font-semibold px-6">
                    {t("common.or")}
                </div>
                <div className="bg-gray-600 flex-1 h-1 rounded"></div>
            </div>

            <div className="flex flex-col space-y-4 my-4">
                <Button
                    className="bg-gray-400 focus:ring-gray-400"
                    text={t("onboarding.seenbefore_diffaccount")}
                    onClick={() => {
                        props?.setIsUserSeen(false);
                    }}
                />
            </div>
        </div>
    );
}
