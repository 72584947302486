import { useReducer } from "react";
import NKAPI from "../components/NKAPI";

const initialState = {
    isLoggedIn: false,
    user: {},
    session: {},
    onboarding: false, // false = login, true = sign up
    age: 0,
    layoutFull: false,
    softKeyboardShown: false,
    isTermsModalShown: false,
    isPrivModalShown: false,
    isOpenDataModalShown: false,
    isOfflineShown: false,
    isErrorShown: false,
    errorMsg: "",
    isNavShown: true,
    skipOnboardingChecks: false,
    linkCodesEnabled: false,
};

// Mutators
const reducer = (state, action) => {
    switch (action.type) {
        case "user/login":
            // Send session ID to api
            NKAPI.setSession(action.payload.session.sessionID);
            // Send user to api
            NKAPI.user = action.payload.user;
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload.user,
                session: action.payload.session,
                // onboarding: true,
                // layoutFull: false,
            };
        case "user/logout":
            NKAPI.setSession("");
            NKAPI.user = {};
            return {
                ...state,
                isLoggedIn: false,
                user: {},
                session: {},
                onboarding: false,
            };
        case "user/update":
            // Send user to api
            NKAPI.user = action.payload.user;
            return {
                ...state,
                user: action.payload.user,
            };
        case "onboarding/update":
            return {
                ...state,
                onboarding: action.payload.onboarding,
            };
        case "onboarding/login":
            return {
                ...state,
                onboarding: false,
            };
        case "onboarding/signup":
            return {
                ...state,
                onboarding: true,
            };
        case "onboarding/age":
            return {
                ...state,
                age: action.payload.age,
            };
        case "onboarding/skipChecks":
            return {
                ...state,
                skipOnboardingChecks: action.payload.skipChecks,
            };
        case "layout/update":
            return {
                ...state,
                layoutFull: action.payload.layoutFull,
            };
        case "ui/show":
            return {
                ...state,
                softKeyboardShown: false,
            };
        case "ui/hide":
            return {
                ...state,
                softKeyboardShown: true,
            };
        case "ui/navigation":
            return {
                ...state,
                isNavShown: action.payload.isShown,
            };
        case "ui/linkCodesEnabled":
            return {
                ...state,
                linkCodesEnabled: action.payload.enabled,
            };
        case "modal/terms":
            return {
                ...state,
                isTermsModalShown: action.payload.isShown,
                isPrivModalShown: false,
            };
        case "modal/priv":
            return {
                ...state,
                isPrivModalShown: action.payload.isShown,
                isTermsModalShown: false,
            };
        case "modal/opendata":
            return {
                ...state,
                isOpenDataModalShown: action.payload.isShown,
                isTermsModalShown: false,
                isPrivModalShown: false,
            };
        case "modal/offline":
            return {
                ...state,
                isOfflineShown: action.payload.isShown,
            };
        case "modal/error":
            return {
                ...state,
                isErrorShown: action.payload.isShown,
                errorMsg: action.payload.errorMsg,
            };
        default: {
            return state;
        }
    }
};

const useGlobalState = () => {
    const [globalState, globalDispatch] = useReducer(reducer, initialState);

    return { globalState, globalDispatch };
};

export default useGlobalState;
