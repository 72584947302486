import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faLockAlt, faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import { useTranslation } from "react-i18next";

export default function EmailSettingsModal(props) {
    const { t } = useTranslation();

    const [isEmailLoading, setIsEmailLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [emailSubmitError, setEmailSubmitError] = useState("");
    const [passSubmitError, setPassSubmitError] = useState("");
    const [isPassLoading, setIsPassLoading] = useState(false);

    function closeModal() {
        props.setIsModalOpen(false);
        setEmailError(false);
        setEmailSubmitError(false);
        setPassSubmitError(false);
    }

    function submitEmailUpdate(e) {
        e.preventDefault();
        setEmailSubmitError("");

        // Get values
        let payload = {
            email: document.getElementById("profile_email").value.trim(),
            password: document.getElementById("profile_password").value
        };

        if (!NKAPI.validateEmail(payload.email)) {
            setEmailError(true);
            return false;
        } else {
            setIsEmailLoading(true);

            NKAPI.request("/user/update/email", payload)
                .then((d) => {
                    if (JSON.parse(d.data.data).success) {
                        props.setEmail(JSON.parse(d.data.data).email);
                        props.setIsModalOpen(false);
                    }
                    setIsEmailLoading(false);
                })
                .catch((e) => {
                    if (
                        e?.response?.data?.error?.type ===
                        "ERR_ACCOUNT_EMAIL_USED"
                    ) {
                        setIsEmailLoading(false);
                        setEmailSubmitError(t("error.email_alreadyused"));
                    } else if (
                        e?.response?.data?.error?.type ===
                        "ERR_ACCOUNT_EMAIL_WRONG_PASSWORD"
                    ) {
                        setIsEmailLoading(false);
                        setEmailSubmitError(t("error.pass_nomatch"));
                    } else {
                        setEmailSubmitError(t("error.error_tryagain"));
                        setIsEmailLoading(false);
                    }
                });
        }
    }
    function submitPassReset() {
        setIsPassLoading(true);
        setPassSubmitError("");
        NKAPI.request("/support/generateNewPassword", { email: props.email })
            .then((d) => {
                // console.log(JSON.parse(d.data.data));
                setIsPassLoading(false);
            })
            .catch((e) => {
                setPassSubmitError(t("error.error_tryagain"));
                setIsPassLoading(false);
            });
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Email Settings"
            className="2xl:w-6/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("misc.email_settings")}
                    </h2>
                    {/* <span className="flag-icon flag-icon-gb"></span> */}
                    <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1 space-y-4">
                    {/* Email Update */}
                    <form onSubmit={submitEmailUpdate} className="space-y-4">
                        <div className="space-y-1">
                            <p className="text-lg">{t("misc.email_change")}</p>
                            <Input
                                id="profile_email"
                                icon={faAt}
                                placeholder={t("misc.email_new")}
                                type="email"
                                state={emailError}
                                errorText={t("error.valid_email")}
                            />
                        </div>
                        <div className="space-y-1">
                            <p className="text-lg">{t("misc.current_pass")}</p>
                            <Input
                                id="profile_password"
                                icon={faLockAlt}
                                placeholder={t("misc.current_pass")}
                                className="mb-4"
                                type="password"
                                autocomplete="current-password"
                            />
                        </div>
                        <div className="flex items-center flex-wrap">
                            <Button
                                text={t("misc.email_update")}
                                spinner={isEmailLoading}
                                type="submit"
                                className="w-auto"
                            />
                            {/* Error */}
                            {emailSubmitError && (
                                <div className="bg-nk rounded-full text-white font-semibold text-sm px-4 py-1 ml-4">
                                    {emailSubmitError}
                                </div>
                            )}
                        </div>
                    </form>

                    <div className="bg-gray-600 h-1 w-full rounded my-7"></div>

                    {/* Password Reset */}
                    <p className="text-lg">{t("misc.pass_reset")}</p>
                    <div className="flex items-center flex-wrap">
                        <Button
                            text={t("misc.reset")}
                            spinner={isPassLoading}
                            type="submit"
                            className="w-72"
                            onClick={submitPassReset}
                        />
                        {/* Error */}
                        {passSubmitError && (
                            <div className="bg-nk rounded-full text-white font-semibold text-sm px-4 py-1 ml-4">
                                {passSubmitError}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
}
