import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFingerprint,
    faLockAlt,
    faTimes
} from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import Input from "../Elements/Input";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import { useTranslation } from "react-i18next";
import Context from "../../store/context";

export default function DeleteAccount(props) {
    const { globalState } = useContext(Context);
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [deleteError, setDeleteError] = useState("");
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    function closeModal() {
        props.setIsModalOpen(false);
        setDeleteError("");
        setDeleteSuccess(false);
    }

    function submitAccountDeletion() {
        let confirmation = document.getElementById("deleteConf").value;

        // If shortcode conf, trim and uppercase
        if (!globalState?.user?.email)
            confirmation = confirmation.trim().toUpperCase();

        setIsLoading(true);
        NKAPI.request("/user/delete", {
            confirmationType: globalState?.user?.email
                ? "EMAIL_PASSWORD"
                : "SHORTCODE_CRC",
            confirmation: confirmation
        })
            .then((d) => {
                if (JSON.parse(d.data.data).success) {
                    setDeleteError("");
                    setDeleteSuccess(true);
                } else {
                    setDeleteError(t("deleteaccount.error"));
                }
                setIsLoading(false);
            })
            .catch((e) => {
                if (
                    e?.response?.data?.error?.error === "ERR_USER_CANT_DELETE_3"
                ) {
                    setDeleteError(t("deleteaccount.errormarked"));
                } else if (
                    e?.response?.data?.error?.error ===
                    "ERR_USER_CANT_DELETE_CONFIRMATION"
                ) {
                    setDeleteError(t("deleteaccount.errorconf"));
                } else {
                    setDeleteError(t("deleteaccount.error"));
                }

                setIsLoading(false);
            });
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Delete Account"
            className="2xl:w-5/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("deleteaccount.title")}
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1">
                    {deleteSuccess ? (
                        <div className="w-full border-blue-500 bg-blue-600 bg-opacity-25 text-gray-100 border-2 rounded-md p-4">
                            <h1 className="text-xl font-semibold">
                                {t("deleteaccount.requestsent")}
                            </h1>
                            <div className="text-lg flex flex-col space-y-3">
                                <p>{t("deleteaccount.requestinfo")}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {/* Warning Box */}
                            <div className="w-full border-red-500 bg-red-600 text-gray-100 border-2 rounded-md p-4">
                                <h1 className="text-xl font-semibold">
                                    {t("deleteaccount.warning")}
                                </h1>
                                <div className="text-lg flex flex-col space-y-3">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: t(
                                                "deleteaccount.warninginfo"
                                            )
                                        }}
                                    ></p>
                                </div>
                            </div>

                            {/* Instructions */}
                            <div>
                                {globalState?.user?.email ? (
                                    // Password Confirmation
                                    <p className="text-base lg:text-lg">
                                        {t("deleteaccount.passconf")}
                                    </p>
                                ) : (
                                    <>
                                        <div className="w-2/4 mx-auto border-gray-600 bg-gray-900 text-gray-100 border-2 rounded-md p-4 mb-4 flex-center">
                                            <p className="text-2xl lg:text-4xl">
                                                {globalState.user.shortcode}
                                            </p>
                                        </div>

                                        {/* // Shortcode Confirmation */}
                                        <p className="text-base lg:text-lg">
                                            {t("deleteaccount.codeconf")}
                                        </p>
                                    </>
                                )}
                                <p className="text-gray-300 text-sm lg:text-base">
                                    {t("deleteaccount.time")}
                                </p>
                            </div>

                            <div>
                                {/* Confirmation Input */}
                                <Input
                                    placeholder={
                                        globalState?.user?.email
                                            ? t("deleteaccount.inputpass")
                                            : t("deleteaccount.inputcode")
                                    }
                                    className="mb-3"
                                    icon={
                                        globalState?.user?.email
                                            ? faLockAlt
                                            : faFingerprint
                                    }
                                    id="deleteConf"
                                    type="password"
                                />

                                {/* Submit Buitton */}
                                <div className="flex items-center flex-wrap">
                                    <Button
                                        text={t("deleteaccount.submit")}
                                        type={
                                            globalState?.user?.email
                                                ? "password"
                                                : "text"
                                        }
                                        className="mr-2"
                                        spinner={isLoading}
                                        onClick={submitAccountDeletion}
                                    />
                                    {/* Error */}
                                    {deleteError && (
                                        <div className="bg-nk rounded-full text-white font-semibold text-sm px-4 py-1 my-2">
                                            {deleteError}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Back */}
                    <Button
                        className="mt-8"
                        text={t("common.back")}
                        color="gray-400"
                        onClick={closeModal}
                    />
                </div>
            </div>
        </Modal>
    );
}
