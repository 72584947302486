import { useEffect, useState, useRef, useContext } from "react";
import { Listbox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSort } from "@fortawesome/pro-solid-svg-icons";
import Button from "../components/Elements/Button";
import NKAPI from "../components/NKAPI";
import Context from "../store/context";
import Input from "../components/Elements/Input";
import { faFileImage, faFileSearch } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoutModal from "../components/Modals/LogoutModal";

export default function Contact() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t } = useTranslation();

    const types = [
        { value: "bug_report", title: t("contact.type_bug") },
        { value: "lost_items", title: t("contact.type_lost") },
        { value: "iap_query", title: t("contact.type_iap") },
        { value: "hacker_report", title: t("contact.type_hacker") },
        { value: "design_feedback", title: t("contact.type_design") },
        { value: "account_issue", title: t("contact.type_account") },
        { value: "other_issue", title: t("contact.type_other") }
    ];

    const [selectedType, setSelectedType] = useState(types[0]);
    const [emailError, setEmailError] = useState(false);
    const [attachment, setAttachment] = useState("");
    const [attachmentFile, setAttachmentFile] = useState(null);
    const [attachmentError, setAttachmentError] = useState("");
    const [attachmentEnabled, setAttachmentEnabled] = useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [offeredLogout, setOfferedLogout] = useState(false);
    const [contactError, setContactError] = useState(false);
    const [contactSuccess, setContactSuccess] = useState(false);
    const [contactSuccessID, setContactSuccessID] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // Refs
    const input_Description = useRef(null);
    // const input_Email = useRef(null);

    const navigate = useNavigate();

    // On Mount
    useEffect(() => {
        // Reset feedback
        setContactError(false);
        setContactSuccess(false);

        // Get email, if user is logged in
        if (
            globalState?.user?.providersAvailable?.indexOf("kloud") > -1 &&
            globalState?.user?.email &&
            !globalState.user.email.includes("@no.email")
        ) {
            document.getElementById("contact_Email").value =
                globalState.user.email;
        }

        // Enable attachments
        if (NKAPI.platform === "ios" && NKAPI.appID !== 11) {
            setAttachmentEnabled(true);
        } else {
            setAttachmentEnabled(false);
        }
        // eslint-disable-next-line
    }, []);

    function navigateToLogout(issue) {
        if (offeredLogout === true) {
            return false;
        }
        issue = issue.replace(/log out/g, "logout");
        issue = issue.replace(/sign out/g, "logout");

        if (
            issue.indexOf("cant logout") !== -1 ||
            issue.indexOf("can't logout") !== -1 ||
            issue.indexOf("let me logout") !== -1 ||
            issue.indexOf("want to logout") !== -1 ||
            issue.indexOf("to logout of my account") !== -1 ||
            issue.indexOf("to logout") !== -1 ||
            issue.indexOf("log me out") !== -1 ||
            issue.indexOf("another account") !== -1 ||
            issue.indexOf("letting me logout") !== -1 ||
            (issue.indexOf("logout") !== -1 && issue.length <= 32)
        ) {
            setOfferedLogout(true);
            return true;
        }

        return false;
    }

    function submitContact() {
        if (isLoading) return;

        setIsLoading(true);
        // Values
        let contactType = selectedType.value;
        let contactDesc = input_Description.current.value;
        let contactEmail = document.getElementById("contact_Email").value;

        // Show logout modal, if certain keywords
        if (navigateToLogout(contactDesc)) {
            // Open Logout modal
            setIsLogoutModalOpen(true);
            setIsLoading(false);
            return;
        }

        // Validate email
        if (!contactEmail || !NKAPI.validateEmail(contactEmail)) {
            setEmailError(true);
            setIsLoading(false);
            return;
        }

        // Send support request
        let payload = {
            body: `${contactDesc}. The user provided the contact address: ${contactEmail}`,
            device: NKAPI.device ? NKAPI.device : "N/A",
            version: NKAPI.version ? NKAPI.version : "N/A",
            type: contactType,
            more: "",
            attachment: attachmentFile
        };

        setContactError(false);
        NKAPI.request("/support/contact", payload)
            .then((d) => {
                // console.log(JSON.parse(d.data.data));
                setIsLoading(false);
                setContactSuccess(true);
                setContactSuccessID(JSON.parse(d.data.data).caseName);
                setContactError(false);
            })
            .catch((e) => {
                // console.log(e.response);
                setContactError(true);
                setIsLoading(false);

                if (e?.toJSON()?.message === "Network Error") {
                    // No internet connection, show lost connection screen
                    globalDispatch({
                        type: "modal/offline",
                        payload: {
                            isShown: true
                        }
                    });
                }
            });
    }

    function addAttachment(e) {
        setAttachmentError("");
        let file = e.target.files[0];

        if (file) {
            // Check size limit
            if (file.size > 8 * 1024 * 1024) {
                setAttachmentError(t("contact.attachment_toobig"));
                return;
            }

            // Set input text
            let extensions = file.name.split(".");
            // Truncate name if too long
            let nameTruncated =
                file.name.length > 24
                    ? `${file.name.substring(0, 19)}....${
                          extensions[extensions.length - 1]
                      }`
                    : file.name;
            setAttachment(nameTruncated);

            // Read file contents
            let reader = new FileReader();

            reader.onload = function (e) {
                var split = e.target.result.split(";");
                var mime = split[0].split(":").pop();
                var body = split[1].split(",").pop();

                // Update state with file contents
                setAttachmentFile({
                    body: body,
                    contentType: mime
                });
            };
            reader.readAsDataURL(file);
        } else {
            // Error
            // setAttachmentError("Error uploading file, please try again");
        }

        // TODO: Submit error feedback/validation
    }

    return (
        <div className="h-full w-full px-1">
            {contactSuccess ? (
                <div className="flex justify-center items-center flex-col">
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="text-green-500 text-5xl mb-4"
                    />

                    <h1 className="text-2xl font-bold text-white">
                        {t("contact.complete_title")}
                    </h1>

                    <p className="text-xl text-white">
                        {t("contact.complete_desc")}
                    </p>
                    <p className="text-gray-400 text-lg mt-4">
                        {t("contact.complete_id", { id: contactSuccessID })}
                    </p>
                </div>
            ) : (
                <>
                    <h1 className="text-3xl font-semibold text-white">
                        {t("contact.title")}
                    </h1>

                    <div className="flex flex-col mt-4">
                        {/* Reason Dropdown */}
                        <h3 className="mb-1 text-lg font-medium text-white">
                            {t("contact.label_reason")}
                        </h3>
                        <Listbox
                            value={selectedType}
                            onChange={(selected) => {
                                for (
                                    let index = 0;
                                    index < types.length;
                                    index++
                                ) {
                                    const el = types[index];
                                    if (el.value === selected) {
                                        setSelectedType(types[index]);
                                    }
                                }
                            }}
                        >
                            {({ open }) => (
                                <>
                                    <div className="relative">
                                        <Listbox.Button
                                            className={`text-base lg:text-xl w-full rounded-full text-white bg-gray-600 h-12 lg:h-14 text-left px-5 focus:outline-none ${
                                                open
                                                    ? "ring-2 ring-nk ring-offset-2 ring-offset-gray-800"
                                                    : ""
                                            }`}
                                        >
                                            <div className="flex items-center justify-between">
                                                {t(
                                                    `contact.type_${
                                                        selectedType.value.split(
                                                            "_"
                                                        )[0]
                                                    }`
                                                )}
                                                <FontAwesomeIcon
                                                    icon={faSort}
                                                    className={`${
                                                        open ? "text-nk" : ""
                                                    }`}
                                                />
                                            </div>
                                        </Listbox.Button>
                                        <Listbox.Options className="bg-gray-700 text-white mt-1 z-50 w-full shadow-md rounded-md p-1 absolute right-0 origin-top-right ring-1 ring-black ring-opacity-5 outline-none">
                                            {types.map((type) => (
                                                <Listbox.Option
                                                    key={type.value}
                                                    value={type.value}
                                                    className="cursor-pointer px-3 py-2 hover:text-white hover:bg-gray-500 rounded-md outline-none"
                                                >
                                                    {type.title}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </>
                            )}
                        </Listbox>

                        {/* Description Box */}
                        <h3 className="mb-1 mt-4 text-lg font-medium text-white">
                            {t("contact.label_description")}
                        </h3>
                        <textarea
                            id="contact_Description"
                            ref={input_Description}
                            className="text-base lg:text-xl bg-gray-600 text-white placeholder-gray-300 rounded-2xl p-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-nk focus:ring-offset-gray-800"
                            rows="8"
                            placeholder={t("contact.placeholder_description")}
                        ></textarea>

                        {/* Email Box */}
                        <h3 className="mb-1 mt-4 text-lg font-medium text-white">
                            {t("contact.label_email")}
                        </h3>
                        <Input
                            id="contact_Email"
                            placeholder={t("contact.label_email")}
                            state={emailError}
                            errorText={t("error.valid_email")}
                            className="pl-2"
                            type="email"
                            onChange={() => {
                                if (emailError) setEmailError(false);
                            }}
                        />

                        {/* Attachments */}
                        {attachmentEnabled && (
                            <>
                                <label
                                    htmlFor="contact_Attachment"
                                    className="relative mt-4 flex-center w-full rounded-full h-12 lg:h-14 bg-gray-200 border-2 border-gray-300 cursor-pointer hover:bg-gray-300"
                                >
                                    <FontAwesomeIcon
                                        icon={
                                            attachment
                                                ? faFileImage
                                                : faFileSearch
                                        }
                                        className="mr-1 lg:mr-3 text-lg lg:text-2xl text-gray-500"
                                    />
                                    {attachment ? (
                                        <>
                                            <p className="text-base lg:text-lg text-gray-500 truncate">
                                                {attachment}
                                            </p>
                                            {/* Remove Attachment */}
                                            <div className="absolute right-0 text-xl hover:text-nk h-full px-4 pr-6 flex-center">
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        setAttachmentFile(null);
                                                        setAttachment("");
                                                        setAttachmentError("");
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-base lg:text-lg text-gray-500">
                                                {t("contact.attachment_title")}
                                            </p>
                                            <p className="text-sm lg:text-base text-gray-400 ml-2">
                                                {t("contact.attachment_size")}
                                            </p>
                                        </>
                                    )}

                                    <input
                                        className="hidden"
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        id="contact_Attachment"
                                        name="contact_Attachment"
                                        onChange={addAttachment}
                                    />
                                </label>
                                {attachmentError && (
                                    <div className="flex-center w-full mt-2">
                                        <div className="bg-nk bg-opacity-80 rounded-full text-white font-semibold text-xs lg:text-sm px-4 py-1 mr-4">
                                            {attachmentError}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {/* Submit */}
                        <Button
                            text={t("common.submit")}
                            className="mt-10"
                            onClick={submitContact}
                            spinner={isLoading}
                        />

                        {/* Back Button */}
                        <Button
                            text={t("common.back")}
                            color="gray-400"
                            className="mt-4 w-48"
                            onClick={() => {
                                navigate(-1);
                            }}
                        />

                        {/* Error */}
                        {contactError && (
                            <div className="mt-4 w-full border-red-500 bg-red-50 border-2 rounded-md p-4">
                                <div className="text-base">
                                    <p className="text-center">
                                        {t("error.contact_error")}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Logout Modal */}
                    {isLogoutModalOpen ? (
                        <LogoutModal
                            isModalOpen={isLogoutModalOpen}
                            setIsModalOpen={setIsLogoutModalOpen}
                        />
                    ) : null}
                </>
            )}
        </div>
    );
}
