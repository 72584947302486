import { useContext } from "react";
import Context from "../../../store/context";

import SignUpAgeGate from "./SignUp_AgeGate";
import SignUpFull from "./SignUp_Full";
import SignUpChild from "./SignUp_Child";

function SignUp() {
    // Global State
    const { globalState } = useContext(Context);
    // const [minAge, setMinAge] = useState(16);

    // On Mount
    // useEffect(() => {
    //     // Get minimum age
    //     NKAPI.getCountry().then((data) => {
    //         if (data?.data === "yes" || JSON.parse(data?.data?.data)?.inEU) {
    //             // Is in EU
    //             setMinAge(16);
    //         } else {
    //             setMinAge(13);
    //         }
    //     });
    // }, []);
    // setMinAge(16);

    return (
        <div>
            {globalState.age > 0 ? (
                globalState.age < 16 ? (
                    <SignUpChild />
                ) : (
                    <SignUpFull />
                )
            ) : (
                <SignUpAgeGate />
            )}
        </div>
    );
}

export default SignUp;
