import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/pro-solid-svg-icons";
import { usePopper } from "react-popper";
import NKAPI from "../NKAPI";

// isoCodes (lang codes) are based on ISO-639
// Flag codes (country codes) are based on ISO-3166-1-alpha-2

function LangSelector() {
    const supportedLangs = [
        {
            isoCode: "en",
            title: "English (US)",
            flag: "us"
        },
        {
            isoCode: "zh_cn",
            title: "简体中文",
            flag: "cn"
        },
        {
            isoCode: "dk",
            title: "Dansk"
        },
        {
            isoCode: "de",
            title: "Deutsche"
        },
        {
            isoCode: "es",
            title: "Español"
        },
        {
            isoCode: "fi",
            title: "Suomalainen"
        },
        {
            isoCode: "fr",
            title: "Français"
        },
        {
            isoCode: "it",
            title: "Italiano"
        },
        {
            isoCode: "ja",
            title: "日本語",
            flag: "jp"
        },
        {
            isoCode: "ko",
            title: "한국어",
            flag: "kr"
        },
        {
            isoCode: "nl",
            title: "Nederlands"
        },
        {
            isoCode: "no",
            title: "Norsk"
        },
        {
            isoCode: "pt",
            title: "Português (BR)"
        },
        {
            isoCode: "ru",
            title: "Pусский"
        },
        {
            isoCode: "se",
            title: "Svenska"
        },
        {
            isoCode: "tr",
            title: "Türk"
        },
        {
            isoCode: "zh_tw",
            title: "繁體中文",
            flag: "tw"
        }
    ];

    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(getCurrentLangData()); // Set default selected lang to current lang

    // Popper
    const [isOpen, setIsOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes, update } = usePopper(
        referenceElement,
        popperElement,
        {
            placement: "top",
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 10]
                    }
                }
            ]
        }
    );

    function getCurrentLangData() {
        let langData = supportedLangs.filter((lang) => {
            return lang.isoCode === NKAPI.locale;
        })[0];

        return langData ? langData : supportedLangs[0];
    }

    function changeLang(e) {
        let selectedLang = e.target.getAttribute("value");

        // Update State
        let desiredLang = supportedLangs.filter((lang) => {
            return lang.isoCode === selectedLang;
        });
        setSelectedLang(desiredLang[0]);

        // Set current lang
        i18n.changeLanguage(selectedLang);

        // Update helper class
        NKAPI.locale = selectedLang;

        // Close select box
        setIsOpen(false);
    }

    // Handle clicks outside select box
    // On Mount
    useEffect(() => {
        document.addEventListener("mousedown", onOutsideClick, false);
        // eslint-disable-next-line
    }, []);

    // On Unmount
    useEffect(
        () => () => {
            document.removeEventListener("mousedown", onOutsideClick, false);
        }, // eslint-disable-next-line
        []
    );

    // Close box when clicking anything except lang box
    function onOutsideClick(e) {
        if (e?.target?.getAttribute("data-popup") === "langBox") {
            if (e?.target?.getAttribute("value")) changeLang(e);

            return;
        } else setIsOpen(false);
    }

    return (
        <div>
            {/* Current Language */}
            <div
                ref={setReferenceElement}
                className="text-white hover:text-gray-200 font-semibold text-sm lg:text-lg uppercase tracking-wide rounded-full focus:outline-none group cursor-pointer select-none"
                onClick={() => {
                    setIsOpen(!isOpen);

                    // Correctly position popper while initially hidden
                    update();
                }}
                data-popup="langBox"
            >
                <div className="flex items-center justify-between space-x-2 pointer-events-none">
                    {/* <span
                        className={`flag-icon flag-icon-${
                            selectedLang.flag
                                ? selectedLang.flag
                                : selectedLang.isoCode
                        } rounded-sm`}
                    ></span> */}
                    <p>{selectedLang.title}</p>
                    <FontAwesomeIcon
                        icon={faSort}
                        className={`text-gray-400 group-hover:text-nk ${
                            isOpen ? "text-nk" : ""
                        }`}
                    />
                </div>
            </div>

            {/* Language Options - Select Box */}
            <div
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className={`bg-gray-700 text-white w-52 shadow-md rounded-md p-1 ring-1 ring-black ring-opacity-5 overflow-y-auto ${
                    window.innerHeight < 450 ? "max-h-48" : "max-h-96"
                } ${isOpen ? "visible" : "invisible"}`}
                data-popup="langBox"
            >
                {supportedLangs.map((lang) => (
                    <div
                        value={lang.isoCode}
                        className="cursor-pointer px-3 py-2 hover:bg-gray-500 hover:text-white rounded-md flex space-x-2"
                        key={lang.isoCode}
                        data-popup="langBox"
                    >
                        {/* <span
                            className={`flag-icon flag-icon-${
                                lang.flag ? lang.flag : lang.isoCode
                            } rounded-sm pointer-events-none`}
                        ></span> */}
                        <p className="pointer-events-none">{lang.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LangSelector;
