import LogoWhite from "../../images/NK_Link_White.svg";
import NKAPI from "../NKAPI";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faQuestionCircle } from "@fortawesome/pro-duotone-svg-icons";
import { useContext, useEffect, useState } from "react";
import AboutModal from "../Modals/AboutModal";
import Context from "../../store/context";

export default function Header() {
    const { globalState } = useContext(Context);
    const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
    const [isCallToActionShown, setIsCallToActionShown] = useState(true);
    const [isTooltipShown, setIsTooltipShown] = useState(false);

    const callToActionTimer = 20000;
    useEffect(() => {
        setTimeout(() => {
            setIsCallToActionShown(false);
        }, callToActionTimer);
    }, []);

    return (
        <header className="bg-gray-800 mb-1 lg:mb-6">
            <div className="container mx-auto h-12 md:h-16 lg:h-20 flex items-center">
                {/* Logo */}
                <div className="flex-1 pr-2 md:pr-3">
                    <img
                        className="w-64 md:w-72 lg:w-80"
                        src={LogoWhite}
                        alt="LiNK WebView"
                    />
                </div>

                {globalState.isNavShown && (
                    <>
                        <div className="relative flex items-center">
                            {/* Buttons */}
                            <FontAwesomeIcon
                                className="mr-3 lg:mr-5 xl:mr-8 text-3xl lg:text-4xl text-gray-400 hover:text-gray-200 cursor-pointer"
                                icon={faQuestionCircle}
                                onClick={() => {
                                    setIsAboutModalOpen(true);
                                }}
                                onPointerEnter={() => {
                                    setIsCallToActionShown(false);
                                    setIsTooltipShown(true);
                                }}
                                onPointerLeave={() => {
                                    setIsTooltipShown(false);
                                }}
                            />
                            {isAboutModalOpen ? (
                                <AboutModal
                                    isModalOpen={isAboutModalOpen}
                                    setIsModalOpen={setIsAboutModalOpen}
                                />
                            ) : null}
                            {/* Call to action */}
                            {isCallToActionShown && (
                                <div
                                    style={{
                                        width: "5.75rem",
                                        left: "-5.8rem"
                                    }}
                                    className="bg-blue-400 rounded-xl text-white font-medium text-xs py-0.5 px-1 md:p-1 md:px-2 animate-bounce-x absolute flex justify-center items-center select-none pointer-events-none"
                                >
                                    <p>Need Help?</p>
                                    {/* <FontAwesomeIcon
                                        className="text-[0.5rem] text-white ml-1"
                                        icon={faArrowRight}
                                        onClick={() => {
                                            setIsAboutModalOpen(true);
                                        }}
                                    /> */}
                                </div>
                            )}
                            {isTooltipShown && (
                                <div
                                    style={{
                                        width: "6.85rem",
                                        left: "-6.9rem"
                                    }}
                                    className="bg-nk rounded-xl text-white font-medium text-xs py-0.5 px-1 md:p-1 md:px-2 absolute flex justify-center items-center select-none pointer-events-none"
                                >
                                    Support / FAQ
                                </div>
                            )}
                        </div>

                        <FontAwesomeIcon
                            className="text-4xl lg:text-5xl text-gray-400 hover:text-gray-200 cursor-pointer"
                            icon={faTimes}
                            onClick={() => {
                                // NKAPI.nkNoti("close event sent");
                                NKAPI.sendToSDK("//close");
                            }}
                        />
                    </>
                )}
            </div>
        </header>
    );
}
