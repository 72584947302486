import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import { useContext } from "react";
import Context from "../../store/context";
import { useTranslation } from "react-i18next";

export default function PrivModal() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    const { t, i18n } = useTranslation();

    function closeModal() {
        globalDispatch({
            type: "modal/priv",
            payload: {
                isShown: false
            }
        });
    }

    function getCountryCode(lang) {
        switch (lang) {
            case "zh_cn":
                return "zh";
            case "zh_tw":
                return "zh-tw";
            case "se":
                return "sv";
            default:
                return lang;
        }
    }

    return (
        <Modal
            isOpen={globalState.isPrivModalShown}
            onRequestClose={closeModal}
            contentLabel="Privacy Policy"
            className="2xl:w-6/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 h-full mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full flex flex-col px-5 md:px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-14 lg:h-20 flex justify-between items-center">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("common.privacy")}
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-400 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto">
                    <div className="text-white legal space-y-2 h-full">
                        <iframe
                            src={`https://static-api.nkstatic.com/appdocs/4/appdocs/privacy_iframe.html?file=privacy_${getCountryCode(
                                i18n.language
                            )}.md#body{color:#ffffff;background-color:#313131;padding-bottom:150px;margin:0;margin-right:10px;}|h2{border-bottom:1px%20solid%20#686868;}|hr{border:%201px%20solid%20#686868;}|::-webkit-scrollbar{width:13px;height:13px;}|::-webkit-scrollbar-thumb{background:#686868;border-radius:9px;}|::-webkit-scrollbar-thumb:hover{background:#969396;}|::-webkit-scrollbar-track{background:#424242;border-radius:9px;box-shadow:inset%200px%200px%200px%200px%20#f0f0f0;}|button{border-radius:25px;outline:none;background-color:#808080;color:white;padding:0.5rem%201rem;border:none;cursor:pointer;}|button:hover{background-color:#707070}|h2:hover{color:#dfdfdf;text-decoration:none;background-color:rgba(0,0,0,0.15);}`}
                            frameBorder="0"
                            className="w-full h-full bg-gray-800"
                            title="Privacy Policy"
                        ></iframe>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
