import i18next from "i18next";
import { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Hero from "./Hero";
import Layout from "./Layout";
import NKAPI from "./NKAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifiSlash } from "@fortawesome/pro-regular-svg-icons";
import Context from "../store/context";

export default function App() {
    // Global State
    const { globalState, globalDispatch } = useContext(Context);

    let navigate = useNavigate();
    const location = useLocation();

    // Link client-side JS injections to new api helper class
    window.$ = {
        api: {
            app: {
                setCurrentProviders: NKAPI.setCurrentProviders,
                setAppInfo: (appInfo) => {
                    NKAPI.setAppInfo(appInfo);
                },
                // When session is set from client, try to log in
                setSession: (sessionID) => {
                    if (!NKAPI.isAppLoaded) {
                        NKAPI.isAppLoaded = true;
                        NKAPI.sendToSDK("//loaded");
                    }
                    // if (window.location.hostname.includes("staging")) {
                    //     fetch(
                    //         encodeURI(
                    //             `https://noti.ninja.kiwi/rossmarc/basic_message/message|Set session: ${sessionID}/`
                    //         )
                    //     );
                    // }
                    NKAPI.setSession(sessionID);
                },
                login: {
                    sessionIsAvailable: LoginWithSession
                },
                userDetailsBackup: () => {
                    if (!NKAPI.isAppLoaded) {
                        NKAPI.isAppLoaded = true;
                        NKAPI.sendToSDK("//loaded");
                    }

                    // if (window.location.hostname.includes("staging")) {
                    //     fetch(
                    //         encodeURI(
                    //             `https://noti.ninja.kiwi/rossmarc/basic_message/message|User details backup/`
                    //         )
                    //     );
                    // }
                }
            },
            user: {
                login: {
                    sessionIsAvailable: LoginWithSession,
                    showToolTipWithText: ReceivedError
                },
                update: {
                    sessionIsAvailable: LoginWithSession,
                    gotoSupport: GoToSupport
                }
            },
            profile: {
                gotoSupport: GoToSupport
            }
        }
    };
    window.showToolTipWithText = ReceivedError;

    function LoginWithSession() {
        if (!NKAPI.isAppLoaded) {
            NKAPI.isAppLoaded = true;
            NKAPI.sendToSDK("//loaded");
        }

        // if (window.location.hostname.includes("staging")) {
        //     fetch(
        //         encodeURI(
        //             `https://noti.ninja.kiwi/rossmarc/basic_message/message|Login with session/`
        //         )
        //     );
        // }
        // Client has requested a login using current sessionID
        if (
            NKAPI.sessionID &&
            !NKAPI.loggingInWithSession &&
            !globalState?.user?.nkapiID
        ) {
            NKAPI.loggingInWithSession = true;
            NKAPI.request("/user/current", {})
                .then((d) => {
                    // Valid session, log user in
                    globalDispatch({
                        type: "user/login",
                        payload: {
                            user: JSON.parse(d.data.data).user,
                            session: JSON.parse(d.data.data).session
                        }
                    });

                    NKAPI.loggingInWithSession = false;
                    // Go to profile page
                    if (!location.pathname.startsWith("/contact"))
                        navigate("/profile");
                })
                .catch((e) => {
                    if (e?.toJSON()?.message === "Network Error") {
                        NKAPI.loggingInWithSession = false;
                        // No internet connection, show lost connection screen
                        globalDispatch({
                            type: "modal/offline",
                            payload: {
                                isShown: true
                            }
                        });
                    }
                });
            // NKAPI.nkNoti("Logging In With Session!");
        } else if (globalState?.user?.nkapiID) {
            NKAPI.loggingInWithSession = false;
            // Go to profile page
            if (!location.pathname.startsWith("/contact")) navigate("/profile");
        }
    }

    function ReceivedError(errorType) {
        if (
            errorType === "ERR_SDK_USER_CANNOT_FIND_LINK" ||
            errorType.includes("login")
        ) {
            // No link acc found with that provider, tell user - this will be checked in loginProviders.js
            NKAPI.errorFromClient = "ERR_SDK_USER_CANNOT_FIND_LINK";
        }

        // Error logging in with provider, cancel spinner state
        NKAPI.hasProviderErrored = true;
    }

    function GoToSupport(reason) {
        if (!NKAPI.isAppLoaded) {
            NKAPI.isAppLoaded = true;
            NKAPI.sendToSDK("//loaded");
        }

        // if (window.location.hostname.includes("staging")) {
        //     fetch(
        //         encodeURI(
        //             `https://noti.ninja.kiwi/rossmarc/basic_message/message|Goto support/`
        //         )
        //     );
        // }
        NKAPI.hiddenSupportInformation = btoa(reason);
        var navigate = useNavigate();
        navigate("/contact");
    }

    // On mount, initialise app
    useEffect(() => {
        // Get app details from client
        // NKAPI.setSession(
        //     NKAPI.sessionID
        //         ? NKAPI.sessionID
        //         : NKAPI.getQueryVariable("sessionID")
        // );
        NKAPI.setAppInfo([
            NKAPI.appID ? NKAPI.appID : NKAPI.getQueryVariable("appID"),
            NKAPI.skuID ? NKAPI.skuID : NKAPI.getQueryVariable("skuID"),
            NKAPI.skuSignature
                ? NKAPI.skuSignature
                : NKAPI.getQueryVariable("secret")
        ]);
        // Get session
        if (NKAPI.getQueryVariable("sessionID")) {
            NKAPI.setSession(NKAPI.getQueryVariable("sessionID"));
            // Login, and send user to profile page - Done in Onboarding/index
        }

        // Get Unity mode
        if (!NKAPI.unityMode) {
            NKAPI.unityMode = NKAPI.getQueryVariable("unity");
        }

        // Get extra info from url
        if (!NKAPI.version) {
            NKAPI.version = NKAPI.getQueryVariable("version");
        }
        if (!NKAPI.device) {
            NKAPI.device = NKAPI.getQueryVariable("device");
        }
        if (!NKAPI.platform) {
            NKAPI.platform = NKAPI.getQueryVariable("platform");
        }

        // Setup debug mode for local test
        NKAPI.debug =
            NKAPI.getQueryVariable("debug") === "true" ||
            NKAPI.getQueryVariable("browser") === "true" ||
            window.location.hostname.startsWith("localhost") ||
            window.location.hostname.startsWith("127.0.0.1") ||
            window.location.hostname.startsWith("192.168");

        // Get providers TODO: Doesn't do anything atm, the platform specific adding overwrites this - Potentially make this overwrite the platform specific ones?
        if (
            NKAPI.availableProviders.length === 0 &&
            NKAPI.getQueryVariable("supports")?.split(",")?.length > 0
        ) {
            NKAPI.availableProviders = NKAPI.translateProviders(
                NKAPI.getQueryVariable("supports").split(",")
            );
        }

        // Manually add providers based on appID and platform if "supports" parameter isn't found
        // GameCircle
        switch (NKAPI.appID) {
            //game circle is only enabled for these games
            case 1: /** BMC */
            case 2: /** Battles */
            case 3: /** SAS4 */
            case 6: /** Fortress */
            case 8: /** TK */
            case 10 /** BSM */:
                if (!NKAPI.availableProviders.includes("gamecircle"))
                    NKAPI.availableProviders.push("gamecircle");
                break;
            default:
                break;
        }
        // Twitch & Facebook
        if (NKAPI.platform === "steam") {
            if (
                !NKAPI.availableProviders.includes("twitch") &&
                !NKAPI.availableProviders.includes("tw")
            )
                NKAPI.availableProviders.push("twitch");

            if (
                !NKAPI.availableProviders.includes("facebook") &&
                !NKAPI.availableProviders.includes("fb")
            )
                NKAPI.availableProviders.push("facebook");
        }
        // Apple
        if (NKAPI.platform === "ios" && NKAPI.appID === 14) {
            if (
                !NKAPI.availableProviders.includes("apple") &&
                !NKAPI.availableProviders.includes("apl")
            )
                NKAPI.availableProviders.push("apple");
        }
        // Android
        if (NKAPI.platform === "android") {
            if (!NKAPI.availableProviders.includes("googleplay"))
                NKAPI.availableProviders.push("googleplay");
        }

        // On load, send loaded response to SDK - Only do this for request that are trying to load sdk pages
        if (!NKAPI.debug && !NKAPI.isAppLoaded) {
            NKAPI.isAppLoaded = true;
            NKAPI.sendToSDK("//loaded");
        }

        // if (window.location.hostname.includes("staging")) {
        //     fetch(
        //         encodeURI(
        //             `https://noti.ninja.kiwi/rossmarc/basic_message/message|Load app/`
        //         )
        //     );
        // }

        // If locale is passed in URL, set lanague to that lang
        if (!NKAPI.locale) {
            // Translate Pop's incorrect ISO codes
            let locale = NKAPI.translateLocales(
                NKAPI.getQueryVariable("locale")
                    ? NKAPI.getQueryVariable("locale")
                    : "en"
            );

            // Set global var and lang selector
            NKAPI.locale = locale;
            i18next.changeLanguage(locale);
        }

        // Get color theme pref
        if (!NKAPI.theme) {
            NKAPI.theme = NKAPI.getQueryVariable("theme");

            if (NKAPI.theme === "light") {
                document.documentElement.classList.remove("dark");
            } else {
                document.documentElement.classList.add("dark");
            }
        }

        // eslint-disable-next-line
    }, []);

    // Run side effect whenever location changes
    // useEffect(() => {
    //     let session = NKAPI.getQueryVariable("sessionID");
    //     if (session) {
    //         NKAPI.sessionID = session;
    //         LoginWithSession();
    //     }
    //     // eslint-disable-next-line
    // }, [location]);

    return (
        <div className="max-h-screen h-screen overflow-hidden md:flex md:flex-row">
            <Hero />
            <Layout />

            {/* Offline Screen */}
            {globalState.isOfflineShown && (
                <div className="fixed h-full w-full bg-black bg-opacity-60 top-0 flex-center">
                    <div className="flex flex-col items-center text-white font-semibold text-center px-2">
                        <FontAwesomeIcon
                            icon={faWifiSlash}
                            className="text-4xl mb-4 text-nk"
                        />
                        <p>Woops! It seems you have lost connection.</p>
                        <p>You might experience issues while using this app.</p>
                        <button
                            className="bg-nk px-5 py-1 rounded-full mt-4 font-semibold"
                            onClick={() => {
                                // Hide screen
                                globalDispatch({
                                    type: "modal/offline",
                                    payload: {
                                        isShown: false
                                    }
                                });
                            }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
