import Button from "../Elements/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import NKAPI from "../NKAPI";
import { useState } from "react";

export default function MarkedForDeletion(props) {
    const { t } = useTranslation(); // locale
    let [isLoading, setIsLoading] = useState(false);
    let [deletionStopped, setDeletionStopped] = useState(false);

    function closeModal() {
        props.setIsModalOpen(false);
    }

    function cancelDeletion() {
        setIsLoading(true);
        NKAPI.request("/user/delete/cancel", {})
            .then((data) => {
                console.log(data);
                setDeletionStopped(true);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Account Marked For Deletion"
            className="2xl:w-4/12 md:w-6/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("deleteaccount.markedmodaltitle")}
                    </h2>
                    <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    />
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1">
                    {deletionStopped ? (
                        <p className="text-base lg:text-lg mb-4">
                            Your account will no longer be deleted.
                        </p>
                    ) : (
                        <p className="text-base lg:text-lg mb-4">
                            This account has been flagged for deletion, if this
                            was a mistake or you no longer want to delete the
                            account. Please press 'Stop Deletion' below.
                        </p>
                    )}

                    {deletionStopped ? (
                        <Button
                            text={t("deleteaccount.markedmodalokay")}
                            color="gray-400"
                            className="mr-4 mb-4"
                            onClick={closeModal}
                        />
                    ) : (
                        <div className="flex flex-wrap">
                            <Button
                                text="Delete Acccount"
                                // TODO: Ttranslate
                                color="gray-400"
                                className="mr-4 mb-4"
                                onClick={closeModal}
                            />
                            <Button
                                text="Stop Deletion"
                                className="mb-4"
                                onClick={cancelDeletion}
                                isLoading={isLoading}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
}
