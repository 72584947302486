import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/actual";
import React from "react";
import { createRoot } from "react-dom/client";
import "./global.css";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalStateProvider from "./store/GlobalStateProvider";
import "./services/locale";
// Components
import App from "./components/App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

import "intl-pluralrules";

function Index() {
    return (
        // Global Router
        <Router>
            {/* Layout */}
            <App />
        </Router>
    );
}

createRoot(document.getElementById("root")).render(
    <GlobalStateProvider>
        <React.StrictMode>
            <React.Suspense
                fallback={
                    <div className="flex-center h-screen w-full">
                        <FontAwesomeIcon
                            className="text-3xl fa-spin text-nk"
                            icon={faSpinnerThird}
                        />
                    </div>
                }
            >
                <Index />
            </React.Suspense>
        </React.StrictMode>
    </GlobalStateProvider>
);
