import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faInfoCircle,
    faSpinnerThird
} from "@fortawesome/pro-duotone-svg-icons";
import { useState } from "react";
import { faPhoneLaptop } from "@fortawesome/pro-regular-svg-icons";
import { usePopper } from "react-popper";

function Button(props) {
    let [isPopoverOpen, setIsPopoverOpen] = useState(false);

    // PopperJS
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "bottom",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, -15]
                }
            }
        ]
    });

    // Format class string
    let buttonClass =
        "select-none flex-center rounded-full h-12 lg:h-14 relative focus:outline-none focus:ring-2 focus:ring-offset-gray-800 focus:ring-offset-2 hover:bg-opacity-90 flex-shrink-0 max-w-full overflow-x-hidden";

    // Use provider color prop if available
    if (props.color) {
        buttonClass += " bg-" + props.color + " focus:ring-" + props.color;
    }
    // If no color prop, or classname with a color, use NK as default color
    else if (!props.color && !props.className?.includes("bg-")) {
        buttonClass += " bg-nk focus:ring-nk";
    }

    if (props.disabled) buttonClass += " opacity-70";

    if (props.width) buttonClass += " " + props.width;
    if (props.className) buttonClass += " " + props.className;

    return (
        <>
            <button
                id={props.id}
                className={buttonClass}
                onClick={props.onClick}
                type={props.type}
                style={props.style}
                disabled={props.disabled}
            >
                {props.spinner ? (
                    <FontAwesomeIcon
                        className="fa-spin text-2xl text-white mx-16"
                        icon={faSpinnerThird}
                    />
                ) : props.div ? (
                    <>{props.div}</>
                ) : (
                    <p className="align-middle text-white font-semibold tracking-wide text-base lg:text-lg px-10 lg:px-14 leading-4 pointer-events-none">
                        {props.text}
                    </p>
                )}

                {props.icon && !props.spinner && (
                    <FontAwesomeIcon
                        className="absolute text-white text-2xl lg:text-3xl ml-4 left-0"
                        icon={props.icon}
                    />
                )}

                {/* Info Popover */}
                {props.infoText && !props.spinner && (
                    <div
                        className="absolute mr-4 right-0 flex-center"
                        ref={setReferenceElement}
                    >
                        <FontAwesomeIcon
                            className="text-white text-3xl lg:text-4xl"
                            icon={faInfoCircle}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsPopoverOpen(!isPopoverOpen);
                            }}
                            onMouseEnter={() => {
                                setIsPopoverOpen(true);
                            }}
                            onMouseLeave={() => {
                                setIsPopoverOpen(false);
                            }}
                        />
                    </div>
                )}

                {props.image && !props.spinner && (
                    <img
                        className="h-7 w-7 lg:h-9 lg:w-9 absolute text-white ml-4 left-0"
                        src={props.image}
                        alt={props.text}
                    ></img>
                )}

                {props.crossplatform && !props.spinner && (
                    <FontAwesomeIcon
                        className="text-xl text-white absolute right-0 mr-5"
                        icon={faPhoneLaptop}
                    />
                )}
            </button>

            {/* Popover Panel */}
            {isPopoverOpen && (
                <div
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className="bg-gray-700 text-white p-3 w-72 shadow-md cursor-auto z-40 mt-0"
                >
                    <p className="text-base font-medium">{props.infoText}</p>
                </div>
            )}
        </>
    );
}

export default Button;
