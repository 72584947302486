import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faApple } from "@fortawesome/free-brands-svg-icons";
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { faSteam } from "@fortawesome/free-brands-svg-icons";
import { useState, useEffect, useContext } from "react";
import Context from "../store/context";
import NKAPI from "../components/NKAPI";
import { useTranslation } from "react-i18next";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

export default function OAuthEnd() {
    const [provider, setProvider] = useState("");
    const [providerIcon, setProviderIcon] = useState(null);
    // Global State
    const { globalDispatch } = useContext(Context);

    const { t } = useTranslation();

    // On mount
    useEffect(() => {
        // Disable Close button
        globalDispatch({
            type: "ui/navigation",
            payload: {
                isShown: false,
            },
        });

        let queryProvier = NKAPI.getQueryVariable("provider");
        if (queryProvier) {
            setProvider(queryProvier);

            switch (queryProvier) {
                case "facebook":
                    setProviderIcon(faFacebook);
                    break;
                case "apple":
                    setProviderIcon(faApple);
                    break;
                case "twitch":
                    setProviderIcon(faTwitch);
                    break;
                case "googleplay":
                    setProviderIcon(faGooglePlay);
                    break;
                case "gamecircle":
                    setProviderIcon(faAmazon);
                    break;
                case "steam":
                    setProviderIcon(faSteam);
                    break;

                default:
                    break;
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="h-full w-full px-1 text-center">
            <FontAwesomeIcon
                className={`text-6xl mb-4 ${
                    providerIcon ? `text-${provider}` : "text-green-400"
                }`}
                icon={providerIcon ? providerIcon : faCheck}
            />

            <div
                className="text-xl font-medium ml-3"
                dangerouslySetInnerHTML={{ __html: t("common.oauth_success") }}
            ></div>
            <div className="hidden text-twitch text-apple text-facebook text-googleplay text-gamecenter text-steam"></div>
        </div>
    );
}
