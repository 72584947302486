import { Routes, Route } from "react-router-dom";
import { useContext } from "react";
import Context from "../../store/context";

// Pages
import Onboarding from "../../pages/Onboarding/";
import ForgotPassword from "../../pages/ForgotPassword";
import PasswordReset from "../../pages/PasswordReset";
import Profile from "../../pages/Profile";
import OAuthEnd from "../../pages/OAuthEnd";
import Contact from "../../pages/Contact";
import Unsub from "../../pages/Unsub";
import Upgrade from "../../pages/Upgrade";

export default function Page() {
    // Global State
    const { globalState } = useContext(Context);
    return (
        <main className="flex-1 bg-gray-800 overflow-y-auto">
            <div className="container h-full mx-auto">
                <Routes>
                    {/* Auth Required */}
                    {globalState.user.nkapiID && (
                        <Route path="/profile" element={<Profile />} />
                    )}
                    <Route path="/unsub" element={<Unsub />} />
                    <Route path="/upgrade" element={<Upgrade />} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                    <Route path="/reset" element={<PasswordReset />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/oauthEnd" element={<OAuthEnd />} />
                    <Route path="*" element={<Onboarding />} />
                </Routes>
            </div>
        </main>
    );
}
