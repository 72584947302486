import Modal from "react-modal";
import Button from "../Elements/Button";
import NKAPI from "../NKAPI";
import { useTranslation } from "react-i18next";

export default function StatusConsentModal(props) {
    const { t } = useTranslation();

    function closeModal() {
        // Update recovery status
        NKAPI.request("/user/consent/update", {
            type: "terms"
        }).finally(() => {
            props.setIsModalOpen(false);
        });
    }

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Consent Modal"
            className="2xl:w-5/12 md:w-9/12 sm:w-10/12 w-11/12 min-w-96 mx-auto bg-gray-800 text-white outline-none shadow-2xl"
            overlayClassName="fixed h-screen w-full top-0 flex-center"
            shouldCloseOnOverlayClick={false}
        >
            <div className="h-full max-h-screen flex flex-col px-8 py-2 lg:px-20 lg:py-8">
                {/* Modal Header */}
                <div className="h-20 flex justify-between items-center px-1">
                    <h2 className="text-xl lg:text-3xl font-semibold">
                        {t("safetypopup.title")}
                    </h2>
                    {/* <FontAwesomeIcon
                        className="text-gray-300 text-3xl lg:text-5xl cursor-pointer hover:text-nk"
                        icon={faTimes}
                        onClick={closeModal}
                    /> */}
                </div>

                {/* Modal Content */}
                <div className="flex-1 overflow-y-auto p-1 text-base lg:text-lg">
                    <p>{t("safetypopup.intro")}</p>
                    <p className="mt-2">{t("safetypopup.list")}</p>
                    <ul className="text-sm lg:text-base list-disc list-inside ml-6 mt-4 text-gray-200">
                        <li>{t("safetypopup.list_1")}</li>
                        <li>{t("safetypopup.list_2")}</li>
                        <li>{t("safetypopup.list_3")}</li>
                    </ul>
                    <p
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                            __html: t("safetypopup.info").replace(
                                "<a>",
                                `<a href="https://ninja.kiwi/support" style="color:#ED1C24">`
                            )
                        }}
                    ></p>
                    <p
                        className="mt-4 text-sm lg:text-base text-gray-300"
                        dangerouslySetInnerHTML={{
                            __html: t("safetypopup.donotaccept", {
                                email: `<a href="mailto:support@ninjakiwi.com" style="color:#ED1C24">support@ninjakiwi.com</a>`
                            })
                        }}
                    ></p>

                    <Button
                        className="mt-4"
                        text={t("safetypopup.accept")}
                        onClick={closeModal}
                    />
                </div>
            </div>
        </Modal>
    );
}
